import React, { useState, useEffect } from 'react';
import {
  View,
  FlatList,
  StyleSheet
} from 'react-native';
import { useTheme, Title, Button, Subheading, Text } from 'react-native-paper';
import { TicketInput, TicketInputRow } from '../Components'
export const Share = (props) => {
  const theme = useTheme();
  const gameTitle = props.route.params.title
  const id = props.route.params.id
  const gametype = props.route.params.gametype
  const { gameid } = props.route.params

  const [offset, setOffset] = useState(1);
  const [messages, setMessages] = useState([]);
  const [dataSource, setDataSource] = useState([]);

  const PairNum = [{ "value": "All Pair", "tickets": "10", "zone_id": "48" }, { "value": "Half Pair", "tickets": "10", "zone_id": "47" }]
  const HouseNum = [{ "value": "0", "tickets": "10", "zone_id": "27" }, { "value": "1", "tickets": "10", "zone_id": "28" }, { "value": "2", "tickets": "10", "zone_id": "29" }, { "value": "3", "tickets": "10", "zone_id": "30" }, { "value": "4", "tickets": "10", "zone_id": "31" }, { "value": "5", "tickets": "10", "zone_id": "32" }, { "value": "6", "tickets": "10", "zone_id": "33" }, { "value": "7", "tickets": "10", "zone_id": "34" }, { "value": "8", "tickets": "10", "zone_id": "35" }, { "value": "9", "tickets": "10", "zone_id": "36" }]
  const EndingNum = [{ "value": "0", "tickets": "10", "zone_id": "37" }, { "value": "1", "tickets": "10", "zone_id": "38" }, { "value": "2", "tickets": "10", "zone_id": "39" }, { "value": "3", "tickets": "10", "zone_id": "40" }, { "value": "4", "tickets": "10", "zone_id": "41" }, { "value": "5", "tickets": "10", "zone_id": "42" }, { "value": "6", "tickets": "10", "zone_id": "43" }, { "value": "7", "tickets": "10", "zone_id": "44" }, { "value": "8", "tickets": "10", "zone_id": "45" }, { "value": "9", "tickets": "10", "zone_id": "46" }]
  const BolNum = [
    { "value": "00", "tickets": "1" }, { "value": "01", "tickets": "1" }, { "value": "02", "tickets": "1" }, { "value": "03", "tickets": "1" }, { "value": "04", "tickets": "1" }, { "value": "05", "tickets": "1" }, { "value": "06", "tickets": "1" }, { "value": "07", "tickets": "1" }, { "value": "08", "tickets": "1" }, { "value": "09", "tickets": "1" },
    { "value": "10", "tickets": "1" }, { "value": "11", "tickets": "1" }, { "value": "12", "tickets": "1" }, { "value": "13", "tickets": "1" }, { "value": "14", "tickets": "1" }, { "value": "15", "tickets": "1" }, { "value": "16", "tickets": "1" }, { "value": "17", "tickets": "1" }, { "value": "18", "tickets": "1" }, { "value": "19", "tickets": "1" },
    { "value": "20", "tickets": "1" }, { "value": "21", "tickets": "1" }, { "value": "22", "tickets": "1" }, { "value": "23", "tickets": "1" }, { "value": "24", "tickets": "1" }, { "value": "25", "tickets": "1" }, { "value": "26", "tickets": "1" }, { "value": "27", "tickets": "1" }, { "value": "28", "tickets": "1" }, { "value": "29", "tickets": "1" },
    { "value": "30", "tickets": "1" }, { "value": "31", "tickets": "1" }, { "value": "32", "tickets": "1" }, { "value": "33", "tickets": "1" }, { "value": "34", "tickets": "1" }, { "value": "35", "tickets": "1" }, { "value": "36", "tickets": "1" }, { "value": "37", "tickets": "1" }, { "value": "38", "tickets": "1" }, { "value": "39", "tickets": "1" },
    { "value": "40", "tickets": "1" }, { "value": "41", "tickets": "1" }, { "value": "42", "tickets": "1" }, { "value": "43", "tickets": "1" }, { "value": "44", "tickets": "1" }, { "value": "45", "tickets": "1" }, { "value": "46", "tickets": "1" }, { "value": "47", "tickets": "1" }, { "value": "48", "tickets": "1" }, { "value": "49", "tickets": "1" },
    { "value": "50", "tickets": "1" }, { "value": "51", "tickets": "1" }, { "value": "52", "tickets": "1" }, { "value": "53", "tickets": "1" }, { "value": "54", "tickets": "1" }, { "value": "55", "tickets": "1" }, { "value": "56", "tickets": "1" }, { "value": "57", "tickets": "1" }, { "value": "58", "tickets": "1" }, { "value": "59", "tickets": "1" },
    { "value": "60", "tickets": "1" }, { "value": "61", "tickets": "1" }, { "value": "62", "tickets": "1" }, { "value": "63", "tickets": "1" }, { "value": "64", "tickets": "1" }, { "value": "65", "tickets": "1" }, { "value": "66", "tickets": "1" }, { "value": "67", "tickets": "1" }, { "value": "68", "tickets": "1" }, { "value": "69", "tickets": "1" },
    { "value": "70", "tickets": "1" }, { "value": "71", "tickets": "1" }, { "value": "72", "tickets": "1" }, { "value": "73", "tickets": "1" }, { "value": "74", "tickets": "1" }, { "value": "75", "tickets": "1" }, { "value": "76", "tickets": "1" }, { "value": "77", "tickets": "1" }, { "value": "78", "tickets": "1" }, { "value": "79", "tickets": "1" },
    { "value": "80", "tickets": "1" }, { "value": "81", "tickets": "1" }, { "value": "82", "tickets": "1" }, { "value": "83", "tickets": "1" }, { "value": "84", "tickets": "1" }, { "value": "85", "tickets": "1" }, { "value": "86", "tickets": "1" }, { "value": "87", "tickets": "1" }, { "value": "88", "tickets": "1" }, { "value": "89", "tickets": "1" },
    { "value": "90", "tickets": "1" }, { "value": "91", "tickets": "1" }, { "value": "92", "tickets": "1" }, { "value": "93", "tickets": "1" }, { "value": "94", "tickets": "1" }, { "value": "95", "tickets": "1" }, { "value": "96", "tickets": "1" }, { "value": "97", "tickets": "1" }, { "value": "98", "tickets": "1" }, { "value": "99", "tickets": "1" },
  ]
  const GroupgNum = [
    { "value": "00", "tickets": "4", "zone_id": "1" }, { "value": "14", "tickets": "8", "zone_id": "9" }, { "value": "01", "tickets": "8", "zone_id": "2" },
    { "value": "22", "tickets": "4", "zone_id": "10" }, { "value": "02", "tickets": "8", "zone_id": "3" }, { "value": "23", "tickets": "8", "zone_id": "11" },
    { "value": "03", "tickets": "8", "zone_id": "4" }, { "value": "24", "tickets": "8", "zone_id": "12" }, { "value": "04", "tickets": "8", "zone_id": "5" },
    { "value": "33", "tickets": "4", "zone_id": "13" }, { "value": "11", "tickets": "4", "zone_id": "6" }, { "value": "34", "tickets": "8", "zone_id": "14" },
    { "value": "12", "tickets": "8", "zone_id": "7" }, { "value": "44", "tickets": "4", "zone_id": "15" }, { "value": "13", "tickets": "8", "zone_id": "8" }
  ]
  const FutaNum = [
    { "value": "0", "tickets": "1", "zone_id": "17" }, { "value": "1", "tickets": "11", "zone_id": "18" }, { "value": "2", "tickets": "11", "zone_id": "19" },
    { "value": "3", "tickets": "11", "zone_id": "20" }, { "value": "4", "tickets": "11", "zone_id": "21" }, { "value": "5", "tickets": "11", "zone_id": "22" },
    { "value": "6", "tickets": "11", "zone_id": "23" }, { "value": "7", "tickets": "11", "zone_id": "24" }, { "value": "8", "tickets": "11", "zone_id": "25" },
    { "value": "9", "tickets": "11", "zone_id": "26" }
  ]

  const processItem=()=>{
    console.log('mm')
  }

  const renderMessage = ({ item }) => {
    switch (gametype) {
      case 'bol':
        return (
          BolNum.map((item, k) =>
            <TicketInput key={k} items={item} gametype={gametype} callback={processItem} disabled={disable} />
          )
        )
      case 'group':
        return (
          GroupgNum.map((item, k) =>
            <TicketInput key={k} items={item} gametype={gametype} callback={processItem} disabled={disable} />
          )
        )
      case 'house':
        return (
          HouseNum.map((item, k) =>
            <TicketInput key={k} items={item} gametype={gametype} callback={processItem} disabled={disable} />
          )
        )
      case 'ending':
        return (
          EndingNum.map((item, k) =>
            <TicketInput key={k} items={item} gametype={gametype} callback={processItem} disabled={disable} />
          )
        )
      case 'pair':
        return (
          PairNum.map((item, k) =>
            <TicketInputRow key={k} items={item} gametype={gametype} callback={processItem} disabled={disable} />
          )
        )
      case 'futa':
        return (
          FutaNum.map((item, k) =>
            <TicketInput key={k} items={item} gametype={gametype} callback={processItem} disabled={disable} />
          )
        )
      default:
        return null;
    }
  }
  const keyExtractor = (item) => item.value;
  const windowSize = 20;
  let num = 100
  let initialLoadNumber = 40

  useEffect(() => {
    switch (gametype) {
      case 'bol':
        setMessages(BolNum);
      case 'group':
        setMessages(GroupgNum);
      case 'house':
        setMessages(HouseNum);
      case 'ending':
        setMessages(EndingNum);
      case 'pair':
        setMessages(PairNum);
      case 'futa':
        setMessages(FutaNum);
      default:
        return null;
    }
  }, [])

  useEffect(() => {
    if (dataSource.length < messages.length) {
      if (offset == 1) {
        setDataSource(messages.slice(0, offset * initialLoadNumber))
      }
    }

  }, [messages]);

  const getData = () => {
    if (dataSource.length < messages.length && messages.length != 0) {
      setOffset(offset + 1);
      setDataSource(messages.slice(0, offset * num))
    }
  };

  return (
    <View
      style={{
        flex: 1,
        backgroundColor: theme.colors.background,
        marginTop:100,
        marginBottom:50
      }}
    >
        {messages.length != 0 && <FlatList
          data={dataSource}
          renderItem={renderMessage}
          inverted={false}
          initialNumToRender={initialLoadNumber}
          windowSize={windowSize} //If you have scroll stuttering but working fine when 'disableVirtualization = true' then use this windowSize, it fix the stuttering problem.
          maxToRenderPerBatch={num}
          updateCellsBatchingPeriod={num / 2}
          keyExtractor={keyExtractor}
          onEndReachedThreshold={offset < 10 ? (offset * (offset == 1 ? 2 : 2)) : 20} //While you scolling the offset number and your data number will increases.So endReached will be triggered earlier because our data will be too many
          onEndReached={getData}
          removeClippedSubviews={true}
          horizontal={false}
          numColumns={2}
          columnWrapperStyle={{width:'110%'}}
          contentContainerStyle={styles.container}
        />
        }
    </View>
  )
};
const styles = StyleSheet.create({
  container: {
    width: '90%', marginLeft: '5%'
  }})