import React from 'react';
import {
  View,
  ScrollView,
  StyleSheet,
  ImageBackground,
  StatusBar, Platform,
  Share,
} from 'react-native';
import { useTheme, Text, Headline, Button, IconButton } from 'react-native-paper';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import { useNavigation } from '@react-navigation/native';
import AwesomeAlert from 'react-native-awesome-alerts';

import { useWalletStore } from '../Services/zustand'
import { Images } from "../Constants";
import { Loader } from '../Components/Loader'
import { getReferral, getReferralWidthdraw, getReferralCode } from "../Services/api"

export const Referral = () => {
  const theme = useTheme();
  const navigation = useNavigation();
  const { winWallet, discountWallet, giftWallet, setWallet } = useWalletStore();
  const [data, setData] = React.useState([{ "total": 0 }, { "wcount": 0 }, { "user": 0 }]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [showAlert, setShowAlert] = React.useState(false);
  const [mTitle, setMTitle] = React.useState('');
  const [mMessage, setMMessage] = React.useState('');

  React.useEffect(() => {
    async function fetchData() {
      const response = await getReferral();
      setData(response.data)
      setIsLoading(false);
    }
    fetchData();
  }, [])
  async function getWidthdraw() {
    setLoading(true)
    const response = await getReferralWidthdraw(data[0].total);
    setWallet({ wallet: response.data, discount: discountWallet, winWallet: winWallet, giftWallet: giftWallet })
    const responseData = await getReferral();
    setData(responseData.data)
    setMTitle('Success')
    setMMessage('Commission transfer successfully!')
    setShowAlert(true)
    setLoading(false)
  }
  const onShare = async () => {
    const response = await getReferralCode();
    let refer_code = response.data
    let link = "Register Here \n https://napp.sgteer.com/signup?ref=" + refer_code + "\n"
    let link1 = "Login Here \n https://napp.sgteer.com/"
    let msg = "GUARANTEED EARN\r\nFREE REAL CASH:\nPLAY AND REFERRAL 2% \n\nOur online app is SAFE and SECURE kindly share to all your Teer player friends.\n\nOur watsapp NO:+917829143623\n\n" + link+ "\n\n" + link1

    try {
      const result = await Share.share({
        message: msg,
      });
      if (result.action === Share.sharedAction) {
        if (result.activityType) {
          // shared with activity type of result.activityType
        } else {
          // shared
        }
      } else if (result.action === Share.dismissedAction) {
        // dismissed
      }
    } catch (error) {
      console.log(error.message);
    }
  };
  function roundToTwo(num) {
    return +(Math.round(num + "e+2") + "e-2");
  }
  StatusBar.setBarStyle("light-content");
  if (Platform.OS === "android") {
    StatusBar.setBackgroundColor("rgba(255,255,255,0)");
    StatusBar.setTranslucent(true);
  }
  if (isLoading) {
    return (<Loader />)
  } else {
    return (
      <View
        style={{
          flex: 1,
          backgroundColor: '#450e52',
        }}
      >
        <ScrollView contentContainerStyle={styles.scrolls}>
          <View style={{ paddingTop: 25, height: 80, flexDirection: 'row' }}>
            <IconButton
              icon="arrow-left"
              color={theme.colors.card}
              size={24}
              onPress={() => navigation.goBack()}
            />
            <Text style={{ color: '#fff', marginVertical: 10, fontSize: 20 }}>Back</Text>
          </View>
          <View style={{ marginBottom: 20, marginHorizontal: 10, overflow: 'hidden', padding: 20, backgroundColor: theme.colors.secondary, height: 250, borderRadius: 10 }}>
            <Text style={{ color: '#fff', marginVertical: 10 }}>Referral Earns</Text>
            <View style={{ width: '100%', flexDirection: 'row', marginBottom: 10 }}>
              <View style={{ width: '73%', flexDirection: 'row', backgroundColor: theme.colors.primary }}>
                <MaterialCommunityIcons name={'currency-inr'} size={24} color={theme.colors.warning} style={{ marginTop: 6 }} />
                {
                  data
                    ?
                    <Headline style={{ color: theme.colors.warning }}>{roundToTwo(data[0].total)}</Headline>
                    :
                    <Headline style={{ color: theme.colors.warning }}>0</Headline>
                }
              </View>
              <Button
                labelStyle={{ color: '#fff' }}
                contentStyle={{ backgroundColor: theme.colors.warning }}
                mode="contained"
                onPress={() => getWidthdraw()}
                loading={loading}
              >
                Claim
              </Button>
            </View>
            <View style={{ width: '100%', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 10 }}>
              <Button
                labelStyle={{ color: '#fff' }}
                contentStyle={{ backgroundColor: theme.colors.info }}
                mode="contained"
                onPress={() => navigation.navigate('CommissionList')}
              >
                My Commission
              </Button>
              <Button
                labelStyle={{ color: '#fff' }}
                contentStyle={{ backgroundColor: theme.colors.lightbg }}
                mode="contained"
                onPress={() => navigation.navigate('ReferralList')}
                style={{ width: '45%' }}
              >
                My Referral
              </Button>
            </View>
            <View style={{ width: '100%', flexDirection: 'row', justifyContent: 'space-between' }}>
              <View style={{ backgroundColor: theme.colors.primary, height: 80, width: '30%', alignItems: 'center', justifyContent: 'center' }}>
                <MaterialCommunityIcons name={'wallet-giftcard'} size={24} color={theme.colors.warning} style={{ marginTop: 6 }} />
                <Text style={{ color: '#fff' }}>{data ? roundToTwo(data[0].total) : 0}</Text>
                <Text style={{ color: '#fff', fontSize: 10 }}>Total Commission</Text>
              </View>
              <View style={{ backgroundColor: theme.colors.primary, height: 80, width: '30%', alignItems: 'center', justifyContent: 'center' }}>
                <MaterialCommunityIcons name={'account-group-outline'} size={24} color={theme.colors.warning} style={{ marginTop: 6 }} />
                <Text style={{ color: '#fff' }}>{data[2].user}</Text>
                <Text style={{ color: '#fff', fontSize: 10 }}>Total Referals</Text>
              </View>
              <View style={{ backgroundColor: theme.colors.primary, height: 80, width: '30%', alignItems: 'center', justifyContent: 'center' }}>
                <MaterialCommunityIcons name={'wallet-plus-outline'} size={24} color={theme.colors.warning} style={{ marginTop: 6 }} />
                <Text style={{ color: '#fff' }}>{data[1].wcount}</Text>
                <Text style={{ color: '#fff', fontSize: 10 }}>Total Withdrawal</Text>
              </View>
            </View>
          </View>
          <View style={{ marginHorizontal: 10, overflow: 'hidden', padding: 10, height: 200, borderRadius: 10, marginVertical: 10 }}>
            <ImageBackground source={Images.reffer} style={styles.image}></ImageBackground>
          </View>
          <View style={{ paddingHorizontal: 20, paddingVertical: 10 }}>
            <View style={{ flexDirection: 'row', alignContent: 'center' }}>
              <View>
                <Text style={{ color: '#fff', fontSize: 14 }}>Guaranteed</Text>
                <Text style={{ color: '#fff', fontSize: 32 }}>Income</Text>
                <Text style={{ color: '#fff', fontSize: 20 }}>Referral Commission</Text>
              </View>
              <View style={{ paddingTop: 15, paddingLeft: 20 }}>
                <Text style={{ color: '#fff', fontSize: 60, marginLeft: 40 }}>2%</Text>
              </View>
            </View>
            <Button
              labelStyle={{ color: '#fff' }}
              contentStyle={{ backgroundColor: theme.colors.primary }}
              mode="contained"
              onPress={() => onShare()}
              style={{ width: '45%' }}
            >
              Refer Now
            </Button>
          </View>
          <AwesomeAlert
            show={showAlert}
            showProgress={false}
            title={mTitle}
            message={mMessage}
            closeOnTouchOutside={true}
            closeOnHardwareBackPress={false}
            showCancelButton={false}
            showConfirmButton={true}
            confirmText="OK"
            cancelButtonColor={theme.colors.secondary}
            onConfirmPressed={() => {
              setShowAlert(false);
              setLoading(false)
            }}
          />
        </ScrollView>
      </View>
    )
  }
}
const styles = StyleSheet.create({
  scrolls: {
    width: '100%',
    flexGrow: 1,
  },
  image: {
    flex: 1,
    resizeMode: 'cover',
    justifyContent: 'center',
    alignItems: 'center',
    ...StyleSheet.absoluteFillObject,
    backgroundColor: '#000',
    opacity: 0.7,
  },
})