import React from 'react';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import { CardStyleInterpolators, createStackNavigator } from '@react-navigation/stack';
import { getFocusedRouteNameFromRoute } from '@react-navigation/native';
import { getHeaderTitle } from '@react-navigation/elements';
import { Text, Button, Appbar, useTheme } from 'react-native-paper';
import { View, StyleSheet } from 'react-native';
import { useWalletStore } from '../Services/zustand'

import { TabNav } from './TabNav';
import { Help, Share, Ticket, Wallet, Process, Game, QrCode, Rates, History, Transaction, Referral, CommissionList, ReferralList, TicketType, BankDetails } from '../Screens/index';
import { ChangePasswordScreen } from '../Screens/Auth/ChangePasswordScreen'

const Stack = createStackNavigator();
const ContentTitle = ({ title, style }) => (
	<Appbar.Content
		title={<Text style={style}> {title} </Text>}
	/>
);

export const StackNav = () => {
	const theme = useTheme();
	const { wallet } = useWalletStore();
	return (
		<Stack.Navigator
			initialRouteName="MHome"
			screenOptions={{
				header: ({ navigation, route, back, options }) => {
					let title = '';
					back ? title = getHeaderTitle(options, route.name) : title = getFocusedRouteNameFromRoute(route)
					return (
						<>
							<View
								theme={{ colors: { primary: theme.colors.background } }}
								style={{ marginBottom: 5, marginTop: 20 }}
							>
								<View style={{ width: '100%', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
									{back ? (
										<View style={{ flexDirection: 'row' }}>
											<Appbar.BackAction
												onPress={navigation.goBack}
												color={theme.colors.primary}
											/>
											<Text
												style={{
													fontSize: 18,
													color: theme.colors.text,
													paddingTop: 10
												}}
											>{title}</Text>
										</View>
									) :
										<>
											<View style={{ width: 40 }}>
												<Appbar.Action
													icon={'menu'}
													accessibilityLabel="TagNotify"
													size={30}
													color={theme.colors.text}
													onPress={() => { navigation.openDrawer() }}
												/>
											</View>
											<ContentTitle
												title={title}
												style={{
													fontSize: 18,
													fontWeight: 'bold',
													color: theme.colors.text,
													width: '100%',
													flex: 1
												}}
											/></>
									}
									<View style={{ flexDirection: 'row', marginRight: 15 }}>
										{wallet !== 0 &&
											<View style={{ width: 100, flexDirection: 'row', height: 30 }}>
												<View style={{ backgroundColor: theme.colors.secondary, justifyContent: 'center', borderTopLeftRadius: 5, borderBottomLeftRadius: 5, width: '30%', justifyContent: 'center', alignItems: 'center' }}>
													<MaterialCommunityIcons name={'wallet-outline'} size={15} color={theme.colors.card} />
												</View>
												<View style={{ flexDirection: 'row', backgroundColor: theme.colors.primary, width: '70%', borderTopEndRadius: 5, borderBottomRightRadius: 5, justifyContent: 'center', alignItems: 'center' }}>
													<MaterialCommunityIcons name={'currency-inr'} size={15} color={theme.colors.card} />
													<Text style={{ color: '#fff' }}>{wallet}</Text>
												</View>
											</View>
										}
									</View>
								</View>
							</View>
						</>
					);
				},
				cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS
			}}
		>
		<Stack.Screen
			name="Help"
			component={Help}
			options={{ headerShown: true, headerTitle: 'Back' }}
			back={{ title: "Back" }}
		/>
		<Stack.Screen
			name="ChangePasswordScreen"
			component={ChangePasswordScreen}
			options={{ headerShown: false, headerTitle: 'ChangePasswordScreen' }}
			back={{ title: "ChangePasswordScreen" }}
		/>
			<Stack.Screen
				name="QrCode"
				component={QrCode}
				options={{ headerShown: true, headerTitle: 'Back' }}
				back={{ title: "Back" }}
			/>
			<Stack.Screen
				name="BankDetails"
				component={BankDetails}
				options={{ headerShown: false,headerTitle: 'Back' }}
				back={{ title: "Back" }}
			/>
			<Stack.Screen
				name="TicketType"
				component={TicketType}
				options={{ headerTitle: 'Back' }}
				back={{ title: "Back" }}
			/>
			<Stack.Screen
				name="ReferralList"
				component={ReferralList}
				options={{ headerShown: false, headerTitle: 'Back' }}
				back={{ title: "Back" }}
			/>
			<Stack.Screen
				name="CommissionList"
				component={CommissionList}
				options={{ headerShown: false, headerTitle: 'Back' }}
				back={{ title: "Back" }}
			/>
			<Stack.Screen
				name="Referral"
				component={Referral}
				options={{ headerShown: false, headerTitle: 'Back' }}
				back={{ title: "Back" }}
			/>
			<Stack.Screen
				name="STransaction"
				component={Transaction}
				options={{ headerTitle: 'Back' }}
				back={{ title: "Back" }}
			/>
			<Stack.Screen
				name="SHistory"
				component={History}
				options={{ headerTitle: "Back" }}
				back={{ title: "Back" }}
			/>
			<Stack.Screen
				name="Rates"
				component={Rates}
				options={{ headerTitle: "Back" }}
				back={{ title: "Back" }}
			/>
			<Stack.Screen
				name="MHome"
				component={TabNav}
				options={{ headerTitle: "Home" }}
				back={{ title: "Back" }}
			/>
			<Stack.Screen
				name="Share"
				component={Share}
				options={{ headerTitle: "Back" }}
				back={{ title: "Share" }}
			/>
			<Stack.Screen
				name="Ticket"
				component={Ticket}
				options={{ headerTitle: "Back" }}
				back={{ title: "Back" }}
			/>
			<Stack.Screen
				name="Wallet"
				component={Wallet}
				options={{ headerTitle: "Back" }}
				back={{ title: "Wallet" }}
			/>
			<Stack.Screen
				name="Process"
				component={Process}
				options={{ headerTitle: "Back" }}
				back={{ title: "Back" }}
			/>
			<Stack.Screen
				name="Game"
				component={Game}
				options={{ headerTitle: "Back" }}
				back={{ title: "Back" }}
			/>
		</Stack.Navigator>
	);
};
