import React from 'react';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import { StyleSheet, View, Linking } from 'react-native';
import { DrawerContentScrollView, DrawerItem } from '@react-navigation/drawer';
import AsyncStorage from '@react-native-async-storage/async-storage';
import AwesomeAlert from 'react-native-awesome-alerts';
import { useNavigation } from '@react-navigation/native';
import {
  Drawer,
  Text,
  Title,
  useTheme,
  Button,
} from 'react-native-paper';
import { getUser } from "../Services/api"
import { useUserStore } from '../Services/zustand'

export function DrawerContent(props) {
  const paperTheme = useTheme();
  const navigation = useNavigation();
  const { user,setUser } = useUserStore();
  const [data, setData] = React.useState([]);
  const [showAlert, setShowAlert] = React.useState(false);

  React.useEffect(() => {
    fetchData();
  }, [])
  async function fetchData() {
    const response = await getUser();
    setUser(response.user)
    setData(response.user)
  }
  const sendWhatsApp = () => {
    let refer_code = user.mobile_no
    let link = "https://api.whatsapp.com/send?phone=917829143623&text=Help!%21%0AMy%20id%20is%20" + refer_code
    Linking.openURL(link)
  };
  return (
    <DrawerContentScrollView {...props} contentContainerStyle={{ flex: 1, backgroundColor: paperTheme.colors.surface }}>
      <View
        style={[
          styles.drawerContent,
          {
            backgroundColor: paperTheme.colors.surface,
          },
        ]}
      >
        <View style={{ width: '90%', marginLeft: '5%', marginTop: 20 }}>
          <Title>Name : {data.name}</Title>
          <Text>User ID : {data.mobile_no}</Text>
          <Text>Referral ID : {data.user_id}</Text>
        </View>
        <Drawer.Section style={styles.drawerSection}>
          <DrawerItem
            icon={({ color, size }) => (
              <MaterialCommunityIcons
                name="history"
                color={color}
                size={size}
              />
            )}
            label="Game History"
            onPress={() => {
              props.navigation.toggleDrawer();
              navigation.push('SHistory')
            }}
          />
          <DrawerItem
            icon={({ color, size }) => (
              <MaterialCommunityIcons
                name="format-list-bulleted"
                color={color}
                size={size}
              />
            )}
            label="Transaction History"
            onPress={() => {
              props.navigation.toggleDrawer();
              navigation.push('STransaction')
            }}
          />
          <DrawerItem
            icon={({ color, size }) => (
              <MaterialCommunityIcons
                name="account-group-outline"
                color={color}
                size={size}
              />
            )}
            label="My Referral"
            onPress={() => {
              props.navigation.toggleDrawer();
              navigation.navigate('Referral')
            }}
          />
          <DrawerItem
            icon={({ color, size }) => (
              <MaterialCommunityIcons
                name="help-circle-outline"
                color={color}
                size={size}
              />
            )}
            label="Help Desk"
            onPress={() => {
              props.navigation.toggleDrawer();
              sendWhatsApp()
            }}
          />
          <DrawerItem
            icon={({ color, size }) => (
              <MaterialCommunityIcons
                name="cash"
                color={color}
                size={size}
              />
            )}
            label="Game Rates & Discount"
            onPress={() => {
              props.navigation.toggleDrawer();
              navigation.navigate('Rates')
            }}
          />
        </Drawer.Section>
        <View style={{ width: '90%', marginLeft: '5%', marginTop: 20 }}>
          <Button onPress={() => setShowAlert(true)} style={{ backgroundColor: paperTheme.colors.success, marginBottom: 20 }}
            labelStyle={styles.text}>
            Logout
          </Button>
          <Button style={{ backgroundColor: paperTheme.colors.info }}
            onPress={() => {
              props.navigation.toggleDrawer();
              navigation.navigate('ChangePasswordScreen')
            }}
            labelStyle={styles.text}>
            Change Password
          </Button>
        </View>

        <View style={{ width: '90%', marginLeft: '5%', marginTop: 20 }}>
          <Title>Privacy Policy:</Title>
          <Text>Dear Online Teer App User,</Text>
          <Text>we promise your data will remain safe and secure used in this app and will not be share to any other third party.</Text>
        </View>
        <AwesomeAlert
          show={showAlert}
          showProgress={false}
          title="Logout"
          message="Are you sure you want to logout!"
          closeOnTouchOutside={true}
          closeOnHardwareBackPress={false}
          showCancelButton={true}
          showConfirmButton={true}
          cancelText="No"
          confirmText="Yes"
          confirmButtonColor={paperTheme.colors.primary}
          onCancelPressed={() => {
            setShowAlert(false);
          }}
          onConfirmPressed={() => {
            props.navigation.toggleDrawer();
            AsyncStorage.clear();
            props.navigation.replace('Auth');
            setShowAlert(false);
          }}
        />
      </View>
    </DrawerContentScrollView>
  );
}
const styles = StyleSheet.create({
  tinyLogo: {
    height: 40
  },
  drawerContent: {
    flex: 1,
  },
  userInfoSection: {
    paddingLeft: 20,
  },
  title: {
    marginTop: 10,
    fontWeight: 'bold',
  },
  caption: {
    fontSize: 14,
    lineHeight: 14,
  },
  row: {
    marginTop: 20,
    flexDirection: 'row',
    alignItems: 'center',
  },
  section: {
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: 15,
  },
  paragraph: {
    fontWeight: 'bold',
    marginRight: 3,
  },
  drawerSection: {
    marginTop: 15,
  },
  preference: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingVertical: 12,
    paddingHorizontal: 16,
  },
  button: {
    width: '100%',
    marginVertical: 10,
    borderRadius: 35,
  },
  text: {
    fontWeight: 'bold',
    fontSize: 15,
    lineHeight: 26,
    color: 'white'
  },
});
