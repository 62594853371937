import React, { useState } from 'react';
import {
  View,
  Alert,
  StyleSheet,
} from 'react-native';
import {
  Button as Buton,
} from 'react-native-paper';
import AwesomeAlert from 'react-native-awesome-alerts';
import { useTheme, Text } from 'react-native-paper';
import Button from './components/Button';
import TextInput from './components/TextInput';
import { phoneValidator, passwordValidator } from './core/utils';

import { loginUser } from "../../Services/api"
import { LoggedInUser } from '../../Services/user';

export const PhoneLogin = ({ navigation }) => {
  const theme = useTheme();
  const [userid, setUserid] = React.useState([]);

  const [mobile, setMobile] = useState({ value: '', error: '' });
  const [mpin, setMpin] = useState({ value: '', error: '' });
  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = React.useState(false);
  const [mTitle, setMTitle] = React.useState('');
  const [mMessage, setMMessage] = React.useState('');

  const _onSendOTPPressed = async () => {
    setLoading(true);
    const mobileError = phoneValidator(mobile.value);
    const passwordError = passwordValidator(mpin.value);
    if (mobileError) {
      setMobile({ ...mobile, error: mobileError });
      return;
    }else if(passwordError){
      setMpin({ ...mpin, error: passwordError });
    } else {
      let responseJson = await loginUser(mobile.value,mpin.value)
      if (responseJson.token) {
				setLoading(false);
				let response = await LoggedInUser(responseJson.token)
				if (response) {
					navigation.replace('DrawerNav');
				} else {
					showMeassage(responseJson.error.password, 'error');
				}
			}else{
        setMTitle('Error')
        setMMessage(responseJson.message)
        setShowAlert(true)
        setLoading(false);
      }
      //if (responseJson.success) {
        //setLoading(false);
        //setUserid(responseJson.data)
        //navigation.navigate('OtpScreen', { 'userid': responseJson.data, 'phone': mobile.value })
      //}
    }
  }
  return (
    <View
      style={{ width: '100%', flex: 1,overflow:'hidden' }}
    >
        <View style={{
          backgroundColor: theme.colors.primary,
          height: 350,
          width: '100%',
          transform: [{ scaleX: 2 }],
          borderBottomStartRadius: 200,
          borderBottomEndRadius: 200,
          overflow: 'hidden',
        }}>
          <View style={{
            width: '90%', marginHorizontal: '5%', flex: 1,
            transform: [{ scaleX: 0.5 }],
            alignItems: 'center',
            justifyContent: 'center',
          }}>
            <Text style={{ fontSize: 24, marginTop: 10, color: theme.colors.card }}>Login Account</Text>
          </View>
        </View>
        <View style={{ borderWidth: .1, elevation: 5, padding: 20, position: 'absolute', width: '90%', marginHorizontal: '5%', backgroundColor: theme.colors.card, marginTop: 260 }}>
        <TextInput
            placeholder="Mobile No"
            value={mobile.value}
            onChangeText={(text) => setMobile({ value: text, error: '' })}
            error={!!mobile.error}
            errorText={mobile.error}
            autoCapitalize="none"
            autoCompleteType="off"
            textContentType="telephoneNumber"
            keyboardType="number-pad"
            underlineColorAndroid="transparent"
            icon="phone-outline"
          />
          <TextInput
            placeholder="Password"
            value={mpin.value}
            onChangeText={(text) => setMpin({ value: text, error: '' })}
            error={!!mpin.error}
            errorText={mpin.error}
            autoCapitalize="none"
            autoCompleteType="off"
            keyboardType="default"
            underlineColorAndroid="transparent"
            icon="form-textbox-password"
            secureTextEntry={true}
          />
          <Button mode="contained" onPress={_onSendOTPPressed} loading={loading} style={{ marginBottom: 25 }}>
            Login
          </Button>
        </View>
        <View style={{ marginTop: 250, justifyContent: 'center', width: '90%', alignItems: 'center', marginHorizontal: '5%' }}>
          <Buton compact={true} contentStyle={{ height: 40 }} mode="text" onPress={() => navigation.navigate('ForgetScreen')}>Forgot Password</Buton>
        </View>
        <AwesomeAlert
          show={showAlert}
          showProgress={false}
          title={mTitle}
          message={mMessage}
          closeOnTouchOutside={true}
          closeOnHardwareBackPress={false}
          showCancelButton={false}
          showConfirmButton={true}
          confirmText="OK"
          cancelButtonColor={theme.colors.primary}
          onCancelPressed={() => {
            setShowAlert(false);
          }}
          onConfirmPressed={() => {
            setShowAlert(false);
          }}
        />
    </View>
  );
};

const styles = StyleSheet.create({
  textInput: {
    height: 40,
    width: '95%',
    borderColor: 'red',
    borderWidth: 2,
    borderRadius: 20,
    marginBottom: 20,
    fontSize: 18,
  },
  forgotPassword: {
    width: '100%',
    alignItems: 'flex-end',
    marginBottom: 24,
  },
  row: {
    flexDirection: 'row',
    margin: 10,
  },
  label: {
    color: 'black',
  },
  link: {
    fontWeight: 'bold',
    color: '#cd7f15',
  },
  borderStyleBase: {
    width: 30,
    height: 45
  },

  borderStyleHighLighted: {
    borderColor: "black",
  },

  underlineStyleBase: {
    width: 30,
    height: 45,
    borderWidth: 0,
    borderBottomWidth: 1,
    color: 'black'
  },

  underlineStyleHighLighted: {
    borderColor: "#03DAC6",
  },
});
