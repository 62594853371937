import config from '../config';
import AsyncStorage from '@react-native-async-storage/async-storage';

export async function loginPhone(phone,pass) {
  const data = {
    mobile_no: phone,
    password: pass
  };
  let url = config.domain + '/api/login';

  const res = await fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((response) => response.json())
  return res
}
export async function registerPhone(phone,pass,referral) {
  const data = {
    mobile_no: phone,
    referral: referral,
    password:pass
  };
  let url = config.domain + '/api/register';
  const res = await fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((response) => response.json())
  return res
}
export async function resendOTP(phone) {
  const data = {
    mobile_no: phone
  };
  let url = config.domain + '/api/otp/resend';
  const res = await fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((response) => response.json())
  return res
}
export async function verifyLogin(input,otp) {
  const data = {
    user_id: input.id,
    otp: otp,
    email:input.email,
    password:"22112211@Mm"
  };
  let url = config.domain + '/api/otp/login';
  const res = await fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((response) => response.json())
  return res
}
export async function registerUser(name, email, password) {
  const data = {
    name: name,
    email: email,
    password: password
  };
  let url = config.domain + '/api/register';
  const res = await fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((response) => response.json())
  return res
}
export async function loginUser(email, password) {
  const data = {
    email: email,
    password: password
  };
  let url = config.domain + '/api/login';
  const res = await fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  }).then((response) => response.json())
  return res
}
export async function gameRates() {
  let utoken = await AsyncStorage.getItem('token');
  let url = config.domain + '/api/rates?token='+utoken;
  const res = await fetch(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then((response) => response.json())
  return res
}
export async function gameCommission() {
  let utoken = await AsyncStorage.getItem('token');
  let url = config.domain + '/api/gamecommission?token='+utoken;
  const res = await fetch(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then((response) => response.json())
  return res
}
export async function gameToday() {
  let utoken = await AsyncStorage.getItem('token');
  let url = config.domain + '/api/results?token='+utoken;
  const res = await fetch(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then((response) => response.json())
  return res
}
export async function walletBalance() {
  let utoken = await AsyncStorage.getItem('token');
  let url = config.domain + '/api/wallet?token='+utoken;
  const res = await fetch(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then((response) => response.json())
  return res
}
export async function gameGameStatus(id) {
  let utoken = await AsyncStorage.getItem('token');
  let url = config.domain + '/api/game/status/';
  const data = {
    gameid:id,
    token: utoken
  };
  const res = await fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  }).then((response) => response.json())
  return res
}
export async function gameTimer(id) {
  let utoken = await AsyncStorage.getItem('token');
  let url = config.domain + '/api/game/timer/';
  const data = {
    gameid:id,
    token: utoken
  };
  const res = await fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  }).then((response) => response.json())
  return res
}
export async function collectGift(amount) {
  let utoken = await AsyncStorage.getItem('token');
  let url = config.domain + '/api/wallet/gift/collect';
  const data = {
    amount: amount,
    token: utoken
  };
  const res = await fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  }).then((response) => response.json())
  return res
}
export async function collectDiscount(amount) {
  let utoken = await AsyncStorage.getItem('token');
  let url = config.domain + '/api/wallet/discount/collect';
  const data = {
    amount: amount,
    token: utoken
  };
  const res = await fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  }).then((response) => response.json())
  return res
}
export async function collectWin(amount) {
  let utoken = await AsyncStorage.getItem('token');
  let url = config.domain + '/api/wallet/win/collect';
  const data = {
    amount: amount,
    token: utoken
  };
  console.log(data)
  const res = await fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  }).then((response) => response.json())
  return res
}
export async function addWallet(amount,txn) {
  let utoken = await AsyncStorage.getItem('token');
  let url = config.domain + '/api/wallet/add';
  const data = {
    txnid:txn,
    deposite: amount,
    token: utoken
  };
  const res = await fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  }).then((response) => response.json())
  return res
}
export async function subWallet(amount) {
  let utoken = await AsyncStorage.getItem('token');
  let url = config.domain + '/api/wallet/withdraw';
  const data = {
    withdraw: amount,
    token: utoken
  };
  const res = await fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  }).then((response) => response.json())
  return res
}
export async function getCart() {
  let utoken = await AsyncStorage.getItem('token');
  let url = config.domain + '/api/carts?token='+utoken;
  const res = await fetch(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then((response) => response.json())
  return res
}
export async function addCart(data) {
  let utoken = await AsyncStorage.getItem('token');
  let url = config.domain + '/api/cart/create?token='+utoken;
  const res = await fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  }).then((response) => response.json())
  return res
}
export async function deleteCart(id) {
  let utoken = await AsyncStorage.getItem('token');
  let url = config.domain + '/api/cart/delete/'+id;
  const data = {
    token: utoken
  };
  const res = await fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  }).then((response) => response.json())
  return res
}
export async function versionChecker() {
  let url = config.domain + '/api/version';
  const res = await fetch(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then((response) => response.json())
  return res
}
export async function getBank() {
  let utoken = await AsyncStorage.getItem('token');
  let url = config.domain + '/api/bank?token='+utoken;
  const res = await fetch(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then((response) => response.json())
  return res
}
export async function addBank(account,ifsc,name,b_name) {
  let utoken = await AsyncStorage.getItem('token');
  let url = config.domain + '/api/bank/create';
  const data = {
    account_no: account,
    ifsc_no: ifsc,
    u_name: name,
    bank_name:b_name,
    token: utoken
  };
  const res = await fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  }).then((response) => response.json())
  return res
}
export async function updateBank(account,ifsc,name,bname,id) {
  let utoken = await AsyncStorage.getItem('token');
  let url = config.domain + '/api/bank/update/'+id;
  const data = {
    account_no: account,
    ifsc_no: ifsc,
    u_name: name,
    bank_name:bname,
    token: utoken
  };
  const res = await fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  }).then((response) => response.json())
  return res
}
export async function getIfsc(code) {
  let utoken = await AsyncStorage.getItem('token');
  let url = config.domain + '/api/bank/ifsc';
  const data = {
    code: code,
    token: utoken
  };
  const res = await fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  }).then((response) => response.json())
  return res
}
export async function getReferralCode() {
  let utoken = await AsyncStorage.getItem('token');
  let url = config.domain + '/api/referral/code?token='+utoken;
  const res = await fetch(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then((response) => response.json())
  return res
}
export async function getAppLink() {
  let utoken = await AsyncStorage.getItem('token');
  let url = config.domain + '/api/app/link?token='+utoken;
  const res = await fetch(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then((response) => response.json())
  return res
}
export async function getReferral() {
  let utoken = await AsyncStorage.getItem('token');
  let url = config.domain + '/api/referral?token='+utoken;
  console.log(url)
  const res = await fetch(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then((response) => response.json())
  return res
}
export async function getReferralMember(page) {
  let utoken = await AsyncStorage.getItem('token');
  let url = config.domain + '/api/referral/members?page='+page+'&token='+utoken;
  const res = await fetch(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then((response) => response.json())
  return res
}
export async function getReferralCommission(page) {
  let utoken = await AsyncStorage.getItem('token');
  let url = config.domain + '/api/referral/collection?page='+page+'&token='+utoken;
  const res = await fetch(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then((response) => response.json())
  return res
}
export async function getReferralWidthdraw(amount) {
  let utoken = await AsyncStorage.getItem('token');
  let url = config.domain + '/api/referral/widthdraw';
  const data = {
    amount: amount,
    token: utoken
  };
  const res = await fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  }).then((response) => response.json())
  return res
}
export async function getHistory(page) {
  let utoken = await AsyncStorage.getItem('token');
  let url = config.domain + '/api/orders?page='+page+'&token='+utoken;
  const res = await fetch(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then((response) => response.json())
  return res
}
export async function getTransaction(page) {
  let utoken = await AsyncStorage.getItem('token');
  let url = config.domain + '/api/wallet/transactions?page='+page+'&token='+utoken;
  const res = await fetch(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then((response) => response.json())
  return res
}
export async function getDiscount(gameId) {
  let utoken = await AsyncStorage.getItem('token');
  let url = config.domain + '/api/discount';
  const data = {
    item_id: gameId,
    token: utoken
  };
  const res = await fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  }).then((response) => response.json())
  return res
}
export async function changePassword(pass) {
  let utoken = await AsyncStorage.getItem('token');
  let url = config.domain + '/api/change/password';
  const data = {
    password: pass,
    token: utoken
  };
  const res = await fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  }).then((response) => response.json())
  return res
}
export async function getUser() {
  let utoken = await AsyncStorage.getItem('token');
  let url = config.domain + '/api/user?token='+utoken;
  const res = await fetch(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then((response) => response.json())
  return res
  return res
}
export async function gameLimits($id) {
  let utoken = await AsyncStorage.getItem('token');
  let url = config.domain + '/api/tickets/limit/'+$id+'?token='+utoken;
  const res = await fetch(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then((response) => response.json())
  return res
}