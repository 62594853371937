import React from 'react';
import {
  View,
  ScrollView,
  StyleSheet,
} from 'react-native';
import { useTheme, Text, Title, Button } from 'react-native-paper';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import { useNavigation } from '@react-navigation/native';

export const Process = ({route}) => {
  const theme = useTheme();
  const navigation = useNavigation();
  const {message}= route.params
  return (
    <View
      style={{
        flex: 1,
        backgroundColor: theme.colors.surface,
      }}
    >
      <ScrollView contentContainerStyle={styles.scrolls}>
        <View style={{ marginTop: 80,marginBottom:80,alignItems:'center' }}>
          <MaterialCommunityIcons name="check-circle" size={120} color={theme.colors.primary} />
          <Title style={{ marginBottom: 10, marginTop: 10 }}>Congrats!</Title>
          <Text style={{ marginBottom: 10, marginTop: 10 }}>{message}</Text>
        </View>
        <Button
          labelStyle={{ lineHeight: 26, color: 'white', }}
          style={{ width: '80%', margin: '10%' }}
          contentStyle={{ height: 50, backgroundColor: theme.colors.primary, borderRadius: 25 }}
          onPress={() => navigation.navigate('Home')}>
          Continue
        </Button>
      </ScrollView>
    </View>
  );
}
const styles = StyleSheet.create({
  scrolls: {
    width: '100%',
    flexGrow: 1,
  },
})
