import React,{ Component } from 'react'
import {
	View,
	Text,
	StyleSheet,
	ImageBackground,
} from 'react-native';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import { useNavigation } from '@react-navigation/native';
import config from './../config'

export const HeaderTitle =(props)=> {
  const image = props.image;
  const BackButton=()=> {
    const navigation = useNavigation();
    return (
      <MaterialCommunityIcons name="arrow-left" size={24} color="white" onPress={() => {
        navigation.goBack();
      }} style={styles.backButton}/>
    );
  }
	return (
		<View style={styles.headerContainer}>
			<ImageBackground source={{uri:image.indexOf('http')!=-1?image:(config.domain.replace("api","")+image)}} style={styles.image}>
				{props.back ? <BackButton/>:(null)}
				{props.title ? <Text style={styles.text}>{props.title}</Text>:(null)}
    	</ImageBackground>
		</View>
	)
}


const styles = StyleSheet.create({
	headerContainer: {
    height:200,
    width:'100%',
  },
  image: {
    flex: 1,
    resizeMode: "cover",
    justifyContent: "center"
	},
	backButton:{
		position:'absolute',
		top:50,
		left:20
	},
  text: {
		fontSize:24,
		textAlign:'center',
		color:'white',
		position:'absolute',
		bottom:10,
		width:'100%',
		fontWeight:'bold',
  }
})
