import React from 'react';
import {
  View,
  ScrollView,
  StyleSheet,
  RefreshControl,
  Modal,
  Linking,
  Pressable
} from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { useTheme, Text, Title, Headline, Button } from 'react-native-paper';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import MaterialIcons from 'react-native-vector-icons/MaterialIcons';

import { TicketCard } from '../Components/TicketCard'
import { Loader } from '../Components/Loader'
import { useWalletStore } from '../Services/zustand'
import { gameToday, walletBalance, versionChecker, collectDiscount, collectWin, collectGift } from "../Services/api"
import config from '../config';

export const Home = () => {
  const theme = useTheme();
  const navigation = useNavigation();
  const { wallet, winWallet, discountWallet, giftWallet, setWallet } = useWalletStore();
  const [today, setToday] = React.useState('')
  const [data, setData] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [gameTitle, setGameTitle] = React.useState('')
  const [gameId, setGameId] = React.useState('')
  const [refreshing, setRefreshing] = React.useState(false);
  const [lodingWin, setLodingWin] = React.useState(false);
  const [lodingDiscount, setLodingDiscount] = React.useState(false);
  const [lodingGift, setLodingGift] = React.useState(false);
  const [modalVisible, setModalVisible] = React.useState(true);
  
  React.useEffect(() => {
    fetchData();
    fetchWallet();
    dateFormat(new Date())
  }, [])
  const onRefresh = React.useCallback(() => {
    setIsLoading(true);
    fetchData();
    fetchWallet();
  }, []);

  async function fetchData() {
    const response = await gameToday();
      response.data.sort(GetSortOrder("id"));
      setData(response.data)
      setIsLoading(false);
  }
  async function fetchWallet() {
    setRefreshing(true);
    let response = await walletBalance();
    setWallet(response.data)
    setRefreshing(false);
  }
  async function fetchGift(amount) {
    setLodingGift(true);
    let response = await collectGift(amount);
    setWallet(response.data)
    setLodingGift(false);
  }
  async function fetchWin(amount) {
    setLodingWin(true);
    let response = await collectWin(amount);
    setWallet(response.data)
    setLodingWin(false);
  }
  async function fetchDiscount(amount) {
    setLodingDiscount(true);
    let response = await collectDiscount(amount);
    setWallet(response.data)
    setLodingDiscount(false);
  }

  const GetSortOrder = (prop) => {
    return function (a, b) {
      if (a[prop] > b[prop]) {
        return 1;
      } else if (a[prop] < b[prop]) {
        return -1;
      }
      return 0;
    }
  }
  const getData = (title, game_id) => {
    setGameTitle(title)
    setGameId(game_id)
  }
  const updateResult = () => {
    fetchData();
  }

  function dateFormat(date) {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    let rdate = day + '-' + month + '-' + year
    setToday(rdate)
  }

  if (isLoading) {
    return (<Loader />)
  } else {
    return (
      <View
        style={{
          flex: 1,
          backgroundColor: theme.colors.background,
        }}
      >
        <ScrollView
          contentContainerStyle={styles.scrolls}
          refreshControl={
            <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
          }
        >
          <View style={{ marginHorizontal: 10, overflow: 'hidden', paddingHorizontal: 10, backgroundColor: theme.colors.info, height: 280, borderRadius: 10 }}>
            <MaterialCommunityIcons name={'currency-inr'} size={200} color={theme.colors.lightbg} style={{ position: 'absolute', top: -25, right: -30 }} />
            <View style={{ backgroundColor: theme.colors.lightbg, position: 'absolute', top: -300, left: -100, height: 350, width: 350, borderRadius: 175 }}>

            </View>
            <View style={{ width: '100%' }}>
              <View>
                <Text style={{ color: theme.colors.card, marginVertical: 5 }}>Cash Wallet: </Text>
                <View style={{ flexDirection: 'row', backgroundColor: theme.colors.lightbg, borderRadius: 5,justifyContent:'space-between' }}>
                  <View style={{ width: 90, flexDirection: 'row' }}>
                    <MaterialCommunityIcons name={'currency-inr'} size={24} color={theme.colors.card} style={{ marginTop: 6 }} />
                    <Headline style={{ color: theme.colors.card }}>{wallet}</Headline>
                  </View>
                  <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Button
                      style={{ borderColor: theme.colors.card, width: 115 }}
                      labelStyle={{ color: theme.colors.card }}
                      mode="outlined"
                      onPress={() => navigation.push('QrCode')}
                    >
                      Add Fund
                    </Button>
                    <Button
                      labelStyle={{ color: theme.colors.text }}
                      style={{ backgroundColor: theme.colors.warning, width: 115 }}
                      mode="contained"
                      onPress={() => navigation.push('Wallet', { opt: 2 })}
                    >
                      Withdraw
                    </Button>
                  </View>
                </View>
              </View>
              <View>
                <Text style={{ color: theme.colors.warning, marginVertical: 5 }}>Discount Wallet: </Text>
                <View style={{ flexDirection: 'row', backgroundColor: theme.colors.lightbg, borderRadius: 5,justifyContent:'space-between' }}>
                  <View style={{ width: '64%', flexDirection: 'row' }}>
                    <MaterialCommunityIcons name={'currency-inr'} size={24} color={theme.colors.warning} style={{ marginTop: 6 }} />
                    <Headline style={{ color: theme.colors.warning }}>{discountWallet}</Headline>
                  </View>
                  <Button
                    style={{ backgroundColor: theme.colors.warning, width: 115 }}
                    labelStyle={{ color: theme.colors.text }}
                    mode="contained"
                    onPress={() => fetchDiscount(discountWallet)}
                    loading={lodingDiscount}
                    disabled={parseInt(discountWallet)>0?false:true}
                  >
                    Claim
                  </Button>
                </View>
              </View>
              <View>
                <Text style={{ color: theme.colors.warning, marginVertical: 5 }}>Win Wallet: </Text>
                <View style={{ flexDirection: 'row', backgroundColor: theme.colors.lightbg, borderRadius: 5,justifyContent:'space-between' }}>
                  <View style={{ width: '64%', flexDirection: 'row' }}>
                    <MaterialCommunityIcons name={'currency-inr'} size={24} color={theme.colors.warning} style={{ marginTop: 6 }} />
                    <Headline style={{ color: theme.colors.warning }}>{winWallet}</Headline>
                  </View>
                  <Button
                    style={{ backgroundColor: theme.colors.warning, width: 115 }}
                    labelStyle={{ color: theme.colors.text }}
                    mode="contained"
                    onPress={() => fetchWin(winWallet)}
                    loading={lodingWin}
                    disabled={parseInt(winWallet)>0?false:true}
                  >
                    Claim
                  </Button>
                </View>
              </View>
              <View>
                <Text style={{ color: theme.colors.warning, marginVertical: 5 }}>Gift Wallet: </Text>
                <View style={{ flexDirection: 'row', backgroundColor: theme.colors.lightbg, borderRadius: 5,justifyContent:'space-between' }}>
                  <View style={{ width: '64%', flexDirection: 'row' }}>
                    <MaterialCommunityIcons name={'currency-inr'} size={24} color={theme.colors.warning} style={{ marginTop: 6 }} />
                    <Headline style={{ color: theme.colors.warning }}>{giftWallet}</Headline>
                  </View>
                  <Button
                    style={{ backgroundColor: theme.colors.warning, width: 115 }}
                    labelStyle={{ color: theme.colors.text }}
                    mode="contained"
                    onPress={() => fetchGift(giftWallet)}
                    loading={lodingGift}
                    disabled={parseInt(giftWallet)>0?false:true}
                  >
                    Claim
                  </Button>
                </View>
              </View>
            </View>
          </View>
          <View style={{justifyContent:'center',alignItems:'center',width:'100%',marginBottom:10}}>
            <Pressable onPress={() => Linking.openURL('https://app.sgteer.com/result')}>
              {({ pressed }) =>
                <Text style={{
                  textDecorationLine: 'underline',
                  color: pressed ? 'red' : 'blue'
                }}>Check Previous Result press here</Text>
              }
            </Pressable>
          </View>
          <View style={{ marginHorizontal: 10, alignItems: 'center' }}>
            <Title style={{ marginBottom: 10, marginTop: 10 }}>{today}</Title>
            {data.map((item, id) => (
              <TicketCard item={item} key={id} passData={getData} callback={updateResult} />
            ))}
          </View>
          <View style={{justifyContent:'center',alignItems:'center',width:'100%',marginBottom:10}}>
            <Pressable onPress={() => Linking.openURL('https://app.sgteer.com/result')}>
              {({ pressed }) =>
                <Text style={{
                  textDecorationLine: 'underline',
                  color: pressed ? 'red' : 'blue'
                }}>Check Previous Result press here</Text>
              }
            </Pressable>
          </View>
          <View style={{ marginHorizontal: 10, overflow: 'hidden', padding: 20, backgroundColor: theme.colors.primary, height: 150, borderWidth: 1, borderRadius: 10, borderColor: theme.colors.secondaryIcon }}>
            <View style={{ opacity: 0.5, position: 'absolute', top: 0, left: 0, bottom: 0, right: 0, height: 100, width: 100 }}>
              <MaterialIcons name="local-offer" size={100} color={theme.colors.secondary} style={{ position: 'absolute', top: 0, right: 10 }} />
            </View>
            <View style={{ backgroundColor: theme.colors.secondary, position: 'absolute', top: 40, right: -110, height: 150, width: 150, borderRadius: 75 }}>

            </View>
            <Headline style={{ color: theme.colors.secondaryCard }}>Offers & Rewards Center</Headline>
            <Text style={{ color: '#fff', marginVertical: 10 }}>Want to earn regular extra Income</Text>
            <Button
              style={{ backgroundColor: theme.colors.secondary, width: 150 }}
              labelStyle={{ color: '#fff' }}
              mode="contained"
              onPress={() => navigation.navigate('Referral')}
            >
              View Now
            </Button>
          </View>
          <Modal
            animationType="slide"
            transparent={true}
            visible={modalVisible}
            onRequestClose={() => {
              setModalVisible(!modalVisible);
            }}>
            <View style={styles.centeredView}>
              <View style={styles.modalView}>
                <Title>Disclaimer</Title>
                <Text style={styles.modalText}>Using this App is at Your Own Risk . . </Text>
                <Text style={styles.modalText}>All the Information Shown on App is based on Numerology and Astrology for information purposes. All data collected from internet .. </Text>
                <Text style={styles.modalText}>We are not associated with any Illegal Teer Business or Gambling … We warn you that teer Gambling in your country may be Banned or illegal .. We are not responsible for any issues or Scam .. </Text>
                <Text style={styles.modalText}>We respect all countries Rules/ Laws .. If you are not agree with our app disclaimer… please quit our App right now .. copying/promoting/publishing any of our content in any type of media or other Source is illegal.</Text>
                <Button
                  style={{ backgroundColor: theme.colors.secondary, width: 130, marginTop: 10 }}
                  labelStyle={{ color: '#fff' }}
                  mode="contained"
                  onPress={() => setModalVisible(!modalVisible)}
                >
                  I Agree
                </Button>
              </View>
            </View>
          </Modal>
        </ScrollView>
      </View>
    );
  }
}
const styles = StyleSheet.create({
  scrolls: {
    width: '100%',
    flexGrow: 1,
  },
  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 22,
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
  },
  modalView: {
    margin: 20,
    backgroundColor: 'white',
    borderRadius: 20,
    padding: 35,
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  button: {
    borderRadius: 20,
    padding: 10,
    elevation: 2,
  },
  modalText: { marginBottom: 10 }
})
