import React, { useState } from 'react';
import {
  View,
  Alert,
  StyleSheet,
} from 'react-native';
import AwesomeAlert from 'react-native-awesome-alerts';
import { useTheme, Text } from 'react-native-paper';
import Button from './components/Button';
import TextInput from './components/TextInput';
import { confirmValidator, passwordValidator } from './core/utils';

import { changePassword } from "../../Services/api"

export const ChangePasswordScreen = ({ navigation }) => {
  const theme = useTheme();
  const [mobile, setMobile] = useState({ value: '', error: '' });
  const [mpin, setMpin] = useState({ value: '', error: '' });
  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = React.useState(false);
  const [mTitle, setMTitle] = React.useState('');
  const [mMessage, setMMessage] = React.useState('');

  const _onSendOTPPressed = async () => {
    setLoading(true);
    const mobileError = passwordValidator(mobile.value);
    const passwordError = confirmValidator(mobile.value, mpin.value);
    if (mobileError) {
      setMobile({ ...mobile, error: mobileError });
      setLoading(false);
      return;
    } else if (passwordError) {
      setMpin({ ...mpin, error: passwordError });
      setLoading(false);
    } else {
      let responseJson = await changePassword(mobile.value)
      if (responseJson.success) {
        setMTitle('Success')
        setMMessage(responseJson.data)
        setShowAlert(true)
        setLoading(false);
      } else {
        setMTitle('Error')
        setMMessage(responseJson.message)
        setShowAlert(true)
        setLoading(false);
      }
    }
  }
  return (
    <View
      style={{ width: '100%', flex: 1, overflow: 'hidden' }}
    >
      <View style={{
        backgroundColor: theme.colors.primary,
        height: 350,
        width: '100%',
        transform: [{ scaleX: 2 }],
        borderBottomStartRadius: 200,
        borderBottomEndRadius: 200,
        overflow: 'hidden',
      }}>
        <View style={{
          width: '90%', marginHorizontal: '5%', flex: 1,
          transform: [{ scaleX: 0.5 }],
          alignItems: 'center',
          justifyContent: 'center',
        }}>
          <Text style={{ fontSize: 24, marginTop: 10, color: theme.colors.card }}>Change Password</Text>
        </View>
      </View>
      <View style={{ borderWidth: .1, elevation: 5, padding: 20, position: 'absolute', width: '90%', marginHorizontal: '5%', backgroundColor: theme.colors.card, marginTop: 260 }}>
        <TextInput
          placeholder="Password"
          value={mobile.value}
          onChangeText={(text) => setMobile({ value: text, error: '' })}
          error={!!mobile.error}
          errorText={mobile.error}
          autoCapitalize="none"
          autoCompleteType="off"
          keyboardType="default"
          underlineColorAndroid="transparent"
          icon="form-textbox-password"
          secureTextEntry={true}
        />
        <TextInput
          placeholder="Confirm Password"
          value={mpin.value}
          onChangeText={(text) => setMpin({ value: text, error: '' })}
          error={!!mpin.error}
          errorText={mpin.error}
          autoCapitalize="none"
          autoCompleteType="off"
          keyboardType="default"
          underlineColorAndroid="transparent"
          icon="form-textbox-password"
          secureTextEntry={true}
        />
        <Button mode="contained" onPress={_onSendOTPPressed} loading={loading} style={{ marginBottom: 25 }}>
          Change Password
        </Button>
      </View>
      <AwesomeAlert
        show={showAlert}
        showProgress={false}
        title={mTitle}
        message={mMessage}
        closeOnTouchOutside={true}
        closeOnHardwareBackPress={false}
        showCancelButton={false}
        showConfirmButton={true}
        confirmText="OK"
        cancelButtonColor={theme.colors.primary}
        onCancelPressed={() => {
          setShowAlert(false);
        }}
        onConfirmPressed={() => {
          setShowAlert(false);          
        navigation.navigate('Home')
        }}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  textInput: {
    height: 40,
    width: '95%',
    borderColor: 'red',
    borderWidth: 2,
    borderRadius: 20,
    marginBottom: 20,
    fontSize: 18,
  },
  forgotPassword: {
    width: '100%',
    alignItems: 'flex-end',
    marginBottom: 24,
  },
  row: {
    flexDirection: 'row',
    margin: 10,
  },
  label: {
    color: 'black',
  },
  link: {
    fontWeight: 'bold',
    color: '#cd7f15',
  },
  borderStyleBase: {
    width: 30,
    height: 45
  },

  borderStyleHighLighted: {
    borderColor: "black",
  },

  underlineStyleBase: {
    width: 30,
    height: 45,
    borderWidth: 0,
    borderBottomWidth: 1,
    color: 'black'
  },

  underlineStyleHighLighted: {
    borderColor: "#03DAC6",
  },
});
