import React, {
  useState,
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { View } from 'react-native';
import { useTheme } from 'react-native-paper';

const Timer2 = forwardRef((props, ref) => {
  const theme = useTheme();
  const [timeStamp, setTimeStamp] = useState(
    props.timestamp ? props.timestamp : 0,
  );

  const [delay, setDelay] = useState(props.delay ? props.delay : 1000);

  const [sendOnce, setSendOnce] = useState(true);

  const [finalDisplayTime, setFinalDisplayTime] = useState('');

  useInterval(() => {
    if (timeStamp > 0) {
      setTimeStamp(timeStamp - 1);
    } else if (sendOnce) {
      if (props.timerCallback) {
        console.log("call")
        props.timerCallback(true);
      } else {
        console.log('Please pass a callback function...');
      }
      setSendOnce(false);
    }
    setFinalDisplayTime(getTimeLeft(timeStamp));
  }, delay);

  function getTimeLeft(until) {
    until = Number(until);
    var h = parseInt(until / (60 * 60), 10) % 24;
    var m = parseInt(until / 60, 10) % 60;
    var s = until % 60;

    var hDisplay = h > 0 ? h + ':' : '0:';
    var mDisplay = m > 0 ? m + ':' : '00:';
    var sDisplay = s > 0 ? s + ':' : '00:';
    return hDisplay + mDisplay + sDisplay;
  };

  const refTimer = useRef();
  useImperativeHandle(ref, () => ({
    resetTimer: () => {
      setTimeStamp(props.timestamp);
      setSendOnce(true);
    },
  }));

  return (
    <View ref={refTimer}>
    </View>
  );
});

function useInterval(callback, delay) {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => {
        clearInterval(id);
      };
    }
  }, [delay]);
}

export default Timer2;