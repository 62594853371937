import { Colors } from 'react-native-paper';

export const white = '#fff'; // white light - white-main dark
export const gray1 = '#F2F2F2';
export const gray2 = '#D5D8DB';
export const gray3 = '#F2F4F9';
export const gray4 = '#ADB5BD';
export const gray5 = '#AAAAAA';
export const gray6 = '#7E8387';
export const gray7 = '#1D2338'; //black light
export const gray8 = '#E2E2E2'; //White-text dark
export const gray9 = '#A2A2A2'; //gray4 dark
export const gray10 = '#616161'; //gray6 dark
export const gray11 = '#1E1E1E'; //bg-second dark
export const gray12 = '#121212'; //bg-black dark
export const gray13 = '#2C2C2C'; //bg-third dark
export const black = '#000';
export const green = Colors.green600;//'#00BA51';
export const red = Colors.red600;//'#FA163F';
export const yellow = Colors.yellow600;//'#FFCD3C';
export const blue = Colors.blue900;
export const brand = Colors.blueA700;
export const blueLight = Colors.blue500; //bg_screens
export const lightbg=Colors.blue800;//ad1e3a
