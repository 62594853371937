import React from 'react';
import { createMaterialBottomTabNavigator } from '@react-navigation/material-bottom-tabs';
import { useTheme } from 'react-native-paper';
import { Home,Transaction,Bank } from '../Screens/index';

const Tab = createMaterialBottomTabNavigator();

export const TabNav = props => {
  const theme = useTheme();

  return (
    <Tab.Navigator
      initialRouteName="Home"
      backBehavior="initialRoute"
      sceneAnimationEnabled={false}
      labeled={true}
      activeColor={ theme.colors.primary }
      barStyle={{ backgroundColor: theme.colors.background }}
    >
    <Tab.Screen
      name="Home"
      component={Home}
      options={{
        title: 'Home',
        tabBarIcon: 'home',
      }}
    />
      <Tab.Screen
        name="Transaction"
        component={Transaction}
        options={{
          title: 'Transaction',
          tabBarIcon: 'swap-horizontal',
        }}
      />
      <Tab.Screen
        name="Bank"
        component={Bank}
        options={{
          tabBarIcon: 'bank-outline',
          title: 'Bank Details',
        }}
      />
    </Tab.Navigator>
  );
};
