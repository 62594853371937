export const emailValidator = email => {
  const re = /\S+@\S+\.\S+/;

  if (!email || email.length <= 0) return 'Email cannot be empty.';
  if (!re.test(email)) return 'Ooops! We need a valid email address.';

  return '';
};

export const passwordValidator = (password) => {
  if (!password || password.length <= 0) return 'Password cannot be empty.';

  return '';
};
export const confirmValidator = (password,cpassword) => {
  if (!password == cpassword) return 'Password mismatch.';

  return '';
};
export const phoneValidator = phone => {
  if (!phone || phone.length <= 0) return 'phone cannot be empty.';
  if (!phone || phone.length < 10) return 'phone need 10 digit number.';

  return '';
};

export const nameValidator = name => {
  if (!name || name.length <= 0) return 'Name cannot be empty.';

  return '';
};
