import * as colors from './colors';
import { DefaultTheme, DarkTheme } from 'react-native-paper';

export const AppLight = {
	...DefaultTheme,
	borderProperty: true,
	colors: {
		...DefaultTheme.colors,
		primary: '#ad1e3a',
		secondary: '#f50057',
		background: colors.gray3,
		card: '#fff',
		text: colors.gray7,
		border: colors.gray2,
		info: colors.blue,
		success: colors.green,
		warning: colors.yellow,
		error: colors.red,
		secondaryText: colors.gray6,
		thirdText: colors.gray4,
		forthText: colors.gray6,
		fifthText: colors.gray4,
		secondaryIcon: colors.gray2,
		secondaryButton: colors.gray1,
		secondaryCard: colors.white,
		secondaryBackground: colors.white,
		thirdCard: colors.gray3,
		modal: colors.gray7,
		textButtonGroup: colors.gray4,
		lightbg:colors.lightbg,
	},
};

export const AppDark = {
	...DarkTheme,
	borderProperty: true,
	colors: {
		...DarkTheme.colors,
		colors: {
			primary: '#ad1e3a',
			secondary: '#f50057',
			background: colors.gray12,
			card: colors.gray13,
			text: colors.gray8,
			border: colors.gray13,
			success: colors.green,
			warning: colors.yellow,
			error: colors.red,
			secondaryText: colors.gray10,
			thirdText: colors.gray9,
			forthText: colors.gray9,
			fifthText: colors.gray10,
			secondaryButton: colors.gray12,
			secondaryIcon: colors.gray10,
			secondaryCard: colors.gray11,
			secondaryBackground: colors.gray12,
			thirdCard: colors.gray11,
			modal: colors.gray12,
			textButtonGroup: colors.gray8,
		},
	},
};
