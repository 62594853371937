import { create } from 'zustand';

export const useCartStore = create((set, get) => ({
  total: 0,
  ticket: [],
  setTicket(newTicket) {
    let n = [...get().ticket]
    n.push(newTicket)
    set({
      ticket: n,
    });
  },
  setTotal(newTicket) {
    let totals= get().total
    set({
      total: totals + newTicket,
    });
  },
  setRefreshTotal(newTicket) {
    set({
      total: newTicket,
    });
  },
}));

export const useUserStore = create(set => ({
  user: {},
  setUser(newuser) {
    set({
      user: newuser,
    });
  },
}))
function roundToTwo(num) {
  return +(Math.round(num + "e+2") + "e-2");
}
export const useWalletStore = create(set => ({
  wallet: 0,
  discountWallet:0,
  winWallet:0,
  giftWallet:0,

  setWallet(newwallet) {
    set({
      wallet: newwallet.wallet,
    });
    set({
      discountWallet: roundToTwo(newwallet.discount),
    });
    set({
      winWallet: newwallet.win,
    });
    set({
      giftWallet: newwallet.gift,
    });
  },
}))