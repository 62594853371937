import React from 'react';
import { StatusBar, Platform } from 'react-native';
import { Provider as PaperProvider } from 'react-native-paper';

import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';

import { AppLight } from './Constants/Themes';

import { SplashScreen } from './Screens/SplashScreen';
import { PhoneLogin } from './Screens/Auth/PhoneLogin';
import { PhoneRegister } from './Screens/Auth/PhoneRegister';
import { ForgetScreen } from './Screens/Auth/ForgetScreen';
import { OtpScreen } from './Screens/Auth/OtpScreen';
import { DrawerNav } from './Nav/DrawerNav';

const Stack = createStackNavigator();

const Auth = () => {
  return (
    <Stack.Navigator initialRouteName="PhoneLogin">
      <Stack.Screen
        name="OtpScreen"
        component={OtpScreen}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="PhoneLogin"
        component={PhoneLogin}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="PhoneRegister"
        component={PhoneRegister}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="ForgetScreen"
        component={ForgetScreen}
        options={{ headerShown: false }}
      />
    </Stack.Navigator>
  );
};
const RootNavigator = () => {
  return (
    <Stack.Navigator initialRouteName="SplashScreen">
      <Stack.Screen
        name="SplashScreen"
        component={SplashScreen}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="Auth"
        component={Auth}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="DrawerNav"
        component={DrawerNav}
        options={{ headerShown: false }}
      />
    </Stack.Navigator>
  )
};
export const Main = () => {
  const useTheme = AppLight

  StatusBar.setBarStyle("dark-content");
  if (Platform.OS === "android") {
    StatusBar.setBackgroundColor("rgba(0,0,0,0)");
    StatusBar.setTranslucent(true);
  }
  return (
    <PaperProvider theme={useTheme}>
      <NavigationContainer theme={useTheme}>
        <RootNavigator />
      </NavigationContainer>
    </PaperProvider>
  )
};
