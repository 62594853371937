import React, { memo } from 'react';
import { StyleSheet } from 'react-native';
import { Button as PaperButton,useTheme } from 'react-native-paper';


const Button = ({ mode, style, children, ...props }) => {
	const theme = useTheme();
  return(
		<PaperButton
    style={[
      styles.button,
      style,
    ,{backgroundColor: theme.colors.primary}]}
    labelStyle={styles.text}
    mode={mode}
    {...props}
  >
    {children}
  </PaperButton>
	)
}

const styles = StyleSheet.create({
  button: {
    width: '100%',
		marginVertical: 10,
		borderRadius:35,
  },
  text: {
    fontWeight: 'bold',
    fontSize: 15,
		lineHeight: 26,
		color:'white'
  },
});

export default memo(Button);
