import React, { memo } from 'react';
import { View, StyleSheet, TextInput as InputText, Text } from 'react-native';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import { useTheme } from 'react-native-paper';

const TextInput = ({ errorText,icon,righticon='', ...props }) => {
	const theme = useTheme();
	return (
		<View style={styles.container}>
      <View style={[styles.inputContainer,{backgroundColor: theme.colors.background,borderColor: theme.colors.primary,}]}>
			<MaterialCommunityIcons name={icon} size={24} color={theme.colors.primary} style={styles.inputIcon}/>
			<InputText
				style={[
					styles.input,
        ]}
        {...props}
			/>
			{righticon!=''?
			<MaterialCommunityIcons name={righticon} size={24} color={theme.colors.primary} style={styles.inputIcon}/>
			:null
			}
      </View>
      {errorText ? <Text style={styles.error}>{errorText}</Text> : null}
		</View>
	);
};

const styles = StyleSheet.create({
  container: {
    width: '100%',
    marginVertical: 12,
  },
	inputContainer: {
		flex: 1,
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
		width: '100%',
		height: 50,
		borderWidth: 1,
	},
	inputIcon: {
		padding: 10,
	},
	input: {
		flex: 1,
		paddingTop: 10,
		paddingRight: 10,
		paddingBottom: 10,
		paddingLeft: 0,
		color: '#424242',
	},
	error: {
		fontSize: 14,
		color: 'red',
		paddingHorizontal: 4,
		paddingTop: 4,
	},
});

export default memo(TextInput);
