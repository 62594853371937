import React from 'react';
import {
  View,
  Linking,
  StyleSheet,
  Platform,
} from 'react-native';
import { useTheme, Text, Button } from 'react-native-paper';

export const ForgetScreen = () => {
  const theme = useTheme();
  const sendWhatsApp = () => {
    let msg = "Please Reset Password!"
    let phoneWithCountryCode = "+919233070576";

    let mobile =
      Platform.OS == "ios" ? phoneWithCountryCode : "+" + phoneWithCountryCode;
    if (mobile) {
      if (msg) {
        let url = "whatsapp://send?text=" + msg + "&phone=" + mobile;
        Linking.openURL(url)
          .then(data => {
            console.log("WhatsApp Opened");
          })
          .catch(() => {
            alert("Make sure WhatsApp installed on your device");
          });
      } else {
        alert("Please insert message to send");
      }
    } else {
      alert("Please insert mobile no");
    }
  };
  return (
    <View style={[styles.container, { backgroundColor: theme.colors.background,margin:'5%' }]}>
      <Text style={{ fontSize: 18, marginBottom: 10 }}>Forget Password</Text>
      <View style={{ borderWidth: 1, borderRadius: 5, padding: 10, backgroundColor: theme.colors.background, elevation: 5, marginTop: 10 }}>
        <Text style={{ fontSize: 22 }}>Note:</Text>
        <Text style={{ fontSize: 18 }}>Please send request on watsapp for reset your password</Text>
        <Text style={{ fontSize: 18 }}>Your new password will get through SMS</Text>
        <Text style={{ fontSize: 18 }}>Thank You</Text>
      </View>
      <Button
        style={{ backgroundColor: theme.colors.primary,marginTop:10 }}
        labelStyle={{ color: '#fff' }}
        mode="contained"
        onPress={() => sendWhatsApp()}
        contentStyle={{width:320,height:50}}
      >
        WhatsApp
      </Button>
    </View>
  )
}
const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    width: '90%',
    height: '100%'
  },
});