import React from 'react';

import {createDrawerNavigator} from '@react-navigation/drawer';

import { StackNav } from './StackNav';
import { DrawerContent } from './DrawerContent';
const Drawer = createDrawerNavigator();

export const DrawerNav = (props) => {
  return (
    <Drawer.Navigator drawerContent={props => <DrawerContent {...props}/>}>
      <Drawer.Screen name="Drawer" component={StackNav} options={{ headerShown: false }}/>
    </Drawer.Navigator>
  );
};