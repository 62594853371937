import React from 'react';
import {
  View,
  ScrollView,
  StyleSheet,
} from 'react-native';
import { useTheme, Text, Title, Caption, Headline, Subheading, Button } from 'react-native-paper';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import MaterialIcons from 'react-native-vector-icons/MaterialIcons';

import { useNavigation } from '@react-navigation/native';

import { Loader } from '../Components/Loader'
import { gameToday } from "../Services/api"

export const Game = (props) => {
  const theme = useTheme();
  const navigation = useNavigation();

  const gameTitle = props.route.params.title
  const gameId = props.route.params.id
  const [gametype, setGametype] = React.useState('')

  return (
    <View
      style={{
        flex: 1,
        backgroundColor: theme.colors.background,
      }}
    >
      <ScrollView contentContainerStyle={styles.scrolls}>
        <View style={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>
          <Text style={{ fontSize: 24,marginBottom:20 }}>{gameTitle}</Text>
          <Button
            style={{ backgroundColor: theme.colors.primary, width: 200,marginBottom:20 }}
            labelStyle={{ color: '#fff' }}
            mode="contained"
            onPress={() => navigation.push('Ticket', { title: gameTitle, id: gameId, gametype: 'bol' })}
            contentStyle={{height:60}}
            >
            Bol/Gutti
          </Button>
          <Button
            style={{ backgroundColor: theme.colors.secondary, width: 200,marginBottom:20 }}
            labelStyle={{ color: '#fff' }}
            mode="contained"
            onPress={() => navigation.push('Ticket', { title: gameTitle, id: gameId, gametype: 'house' })}
            contentStyle={{height:60}}
            >
            House
          </Button>
          <Button
            style={{ backgroundColor: theme.colors.primary, width: 200,marginBottom:20 }}
            labelStyle={{ color: '#fff' }}
            mode="contained"
            onPress={() => navigation.push('Ticket', { title: gameTitle, id: gameId, gametype: 'ending' })}
            contentStyle={{height:60}}
            >
            Ending
          </Button>
          <Button
            style={{ backgroundColor: theme.colors.secondary, width: 200,marginBottom:20 }}
            labelStyle={{ color: '#fff' }}
            mode="contained"
            onPress={() => navigation.push('Ticket', { title: gameTitle, id: gameId, gametype: 'allpair' })}
            contentStyle={{height:60}}
            >
            All Pair
          </Button>
          <Button
            style={{ backgroundColor: theme.colors.primary, width: 200,marginBottom:20 }}
            labelStyle={{ color: '#fff' }}
            mode="contained"
            onPress={() => navigation.push('Ticket', { title: gameTitle, id: gameId, gametype: 'halfpair' })}
            contentStyle={{height:60}}
            >
            Half Pair
          </Button>
          <Button
            style={{ backgroundColor: theme.colors.secondary, width: 200,marginBottom:20 }}
            labelStyle={{ color: '#fff' }}
            mode="contained"
            onPress={() => navigation.push('Ticket', { title: gameTitle, id: gameId, gametype: 'group' })}
            contentStyle={{height:60}}
          >
            Group
          </Button>
        </View>
      </ScrollView>
    </View>
  )
}
const styles = StyleSheet.create({
  scrolls: {
    width: '100%',
    flexGrow: 1,
  },
})