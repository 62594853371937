import React from 'react';
import {
  View,
} from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { useTheme, Text, Caption, Headline, Subheading, Button } from 'react-native-paper';
import Timer from '../Components/Timer'
import Timer2 from '../Components/Timer2'

export const TicketCard = (props) => {
  const theme = useTheme();
  const navigation = useNavigation();

  const [disable, setDisable] = React.useState(false)
  const convertTime24_12 = t => {
    let [h, ...rest] = t.split(":");
    return (h == "12" ? "12" : h % 12) + ":" + rest.join(":") + (h < 12 ? " AM" : " PM");
  }
  const { item } = props;
  const timerCallbackFunc = () => {
    setDisable(true)
  }
  const timer2CallbackFunc = () => {
    props.callback()
  }
  React.useEffect(() => {
    if (item.game.status == 0) {
      setDisable(true)
    }
  }, [item.game.status])
  return (
    <View style={{ alignItems: 'center', width: '90%', marginBottom: 20, backgroundColor: theme.colors.card, borderColor: theme.colors.background, borderWidth: 1, borderRadius: 15, elevation: 15 }}>
      <View style={{ width: '100%', justifyContent: 'center', alignItems: 'center', paddingBottom: 10 }}>
        <Subheading>{item.game.title}</Subheading>
        <Headline style={{ color: '#000' }}>{item.result_no!=null?item.result_no:'-'}</Headline>
        <Caption style={{ color: '#000' }}>{convertTime24_12(item.game.time)}</Caption>
        <View style={{ position: 'absolute', top: 40, left: -15, height: 48, width: 100, borderRadius: 5, justifyContent: 'center', alignItems: 'center' }}>
          {item.game.status == 1 ?
            <>
              <Timer
                timestamp={item.rotator}
                timerCallback={timerCallbackFunc}
              />
              <Timer2
                timestamp={item.interval}
                timerCallback={timer2CallbackFunc}
              />
            </>
            :
            <View style={{ backgroundColor: theme.colors.thirdText, height: 35, width: 110, borderRadius: 5, justifyContent: 'center', alignItems: 'center' }}>
              <Text style={{ color: theme.colors.card, fontWeight: 'bold' }}>Time Up</Text>
            </View>
          }
        </View>
        <View style={{ position: 'absolute', top: 40, right: -20 }}>
          <Button
            style={{ backgroundColor: disable ? theme.colors.thirdText : theme.colors.primary }}
            disabled={disable}
            labelStyle={{ color: '#fff' }}
            mode="contained"
            onPress={() => navigation.navigate('TicketType', { house:item.game.house,title: item.game.title, gameid: item.id, id: item.game.id, timer: item.rotator })}
          >
            Bid Now
          </Button>
        </View>
        <View style={{ backgroundColor: theme.colors.border, width: '100%', height: 1, marginTop: 10 }}></View>
        <Caption style={{ color: '#000' }}>8:00 am to {item.close}</Caption>
      </View>
    </View>
  )
}