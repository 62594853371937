import React from "react";
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { Main } from './src/main';
import * as serviceWorkerRegistration from './src/serviceWorkerRegistration';
const App = () => {
  serviceWorkerRegistration.register();
  return (
    <SafeAreaProvider>
      <Main />
    </SafeAreaProvider>
  );
};

export default App
