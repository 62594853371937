import React from 'react';
import {
  View,
  ScrollView,
  StyleSheet,
  RefreshControl,
} from 'react-native';
import { useTheme, Text, ProgressBar } from 'react-native-paper';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import EvilIcons from 'react-native-vector-icons/EvilIcons';

import { GameStatus } from '../Components/GameStatus'
import { Loader } from '../Components/Loader'
import { getHistory } from "../Services/api"
import Pagination from '../Components/Pagination'

export const History = () => {
  const theme = useTheme();
 
  const [isLoading, setIsLoading] = React.useState(true);
  const [data, setData] = React.useState([]);
  const [pagenum, setPagenum] = React.useState(1);
  const [links, setLinks] = React.useState([]);
  const [refreshing, setRefreshing] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const fetchData = async (page) => {
    const response = await getHistory(page);
    setData(response.data)
    setIsLoading(false);
    setLinks(response.pagination)
  }
  const winamount = (ticket) => {
    let totalTicket = ticket.reduce(function (sum, tic) {
      return sum + parseInt(tic.amount);
    }, 0);
    return totalTicket;
  }
  React.useEffect(() => {
    fetchData(pagenum);
  }, [])

  const onRefresh = React.useCallback(() => {
    setRefreshing(true);
    fetchData(pagenum);
    setRefreshing(false);
  }, []);

  const pageChange=(page)=>{
    //console.log(page)
    setLoading(true)
    setPagenum(page)
    fetchData(page);
    setLoading(false)
  }
  if (isLoading) {
    return (<Loader />)
  } else {
    return (
      <View
        style={{
          flex: 1,
          backgroundColor: theme.colors.background,
        }}
      >
        <ScrollView
          contentContainerStyle={styles.scrolls}
          refreshControl={
            <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
          }>
          {loading?<ProgressBar indeterminate={true} color={theme.colors.primary} />:null}          
          {data.length > 0 ?
            <View style={{ marginTop: 10, width: '90%', marginHorizontal: '5%' }}>
              {
                data.map((item, k) => (
                  <View key={k} style={{ marginBottom: 10, padding: 10, elevation: 2, borderWidth: .01,backgroundColor:theme.colors.card }}>
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                      <Text>Ticket Id:#{item.id}</Text>
                      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                        <MaterialCommunityIcons name={'currency-inr'} size={15} color={theme.colors.text} />
                        <Text>{item.amount + '.00'}</Text>
                      </View>
                    </View>
                    <View style={{ alignItems: 'center', justifyContent: 'space-evenly', flexDirection: 'row' }}>
                      <Text>{item.created_at}</Text><Text>{item.time_at}</Text>
                    </View>
                    <View>
                      {item.ticket.map((yitem, y) => (
                        <View key={k + y} style={{ padding: 5, width: '100%', borderTopWidth: 1, borderColor: theme.colors.border }}>
                          <View style={{ padding: 10, width: '100%', flex: 1, flexDirection: 'row', alignContent: 'center', alignItems: 'center', borderColor: theme.colors.border, borderBottomWidth: 1 }}>
                            <Text style={{ width: '40%', textAlign: 'center' }}>{yitem.title}</Text>
                            <Text style={{ width: '20%', textAlign: 'center' }}>{yitem.num}</Text>
                            <Text style={{ width: '20%', textAlign: 'center' }}>{yitem.gametype}</Text>
                            <View style={{ flexDirection: 'row', alignItems: 'center', width: '20%' }}>
                              <MaterialCommunityIcons name={'currency-inr'} size={15} color={theme.colors.text} />
                              <Text>{yitem.amount + '.00'}</Text>
                            </View>
                          </View>
                          <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                            {yitem.winamount.map((titem, t) => (
                              <Text style={{ padding: 5, color: parseInt(titem.amount) > 1 ? 'red' : 'black' }} key={t + y + k}>{titem.number}</Text>
                            ))}
                          </View>
                          <GameStatus id={item.id} winamount={winamount(yitem.winamount)} status={item.status}/>
                        </View>
                      ))}
                    </View>
                  </View>
                ))
              }
            </View>
            :
            <View style={{ width: '100%', flex: 1, flexDirection: 'column', alignContent: 'center', alignItems: 'center', justifyContent: 'center' }}>
              <EvilIcons name="cart" size={100} color="black" />
              <Text style={{ margin: 10 }}>No Order History</Text>
              <Text>Please Go Home and Purchase first</Text>
            </View>
          }
        </ScrollView>
        {links.total>links.pageSize?
        <View style={{ borderWidth: 1, borderColor: theme.colors.border, height: 50, justifyContent: 'center', alignItems: 'center' }}>
          <Pagination onPageChange={pageChange} totalCount={links.total} currentPage={links.current_page} pageSize={links.pageSize} />
        </View>
        :null}
      </View >
    )
  }
}
const styles = StyleSheet.create({
  scrolls: {
    width: '100%',
    flexGrow: 1,
  },
})