import React, { useEffet, useState } from 'react';
import {
  Keyboard,
  View,
  Alert,
  StyleSheet,
  KeyboardAvoidingView,
  ScrollView,
} from 'react-native';
import {
  Snackbar,
  Button as Buton,
} from 'react-native-paper';
import { useNavigation } from '@react-navigation/native';

import { useTheme, Text } from 'react-native-paper';
import Button from './components/Button';
import { resendOTP, verifyLogin } from "../../Services/api"
import { LoggedInUser } from '../../Services/user';

export const OtpScreen = ({ route }) => {
  const theme = useTheme();
  const navigation = useNavigation();
  const {phone} = route.params;
  const {userid} = route.params;
  const [otp, setOtp] = React.useState('');

  const [loading, setLoading] = useState(false);
  
  const _verifyotp = async () => {
    setLoading(true);
    let responseJson = await verifyLogin(userid, otp)
    if (responseJson.token) {
      response = await LoggedInUser(responseJson.token)
      if (response) {
        setLoading(false);
        navigation.replace('DrawerNav');
      } else {
        Alert.alert('OTP', responseJson.error.password, [
          { text: 'OK', onPress: () => console.log("mm") },
        ]);
      }
    } else {
      Alert.alert('OTP', responseJson.message, [
        { text: 'OK', onPress: () => console.log("mm") },
      ]);
      setLoading(false);
    }
  }

  const resendO = async () => {
    let responseJson = await resendOTP(phone)
    if (responseJson.message) {
      setLoading(false);
      Alert.alert('OTP Resend', responseJson.message, [
        { text: 'OK', onPress: () => console.log('PhoneRegister') },
      ]);
    }
  }

  return (
    <View
      style={{ width: '100%', flex: 1 }}
    >
      <View style={{
        backgroundColor: theme.colors.primary,
        height: 400,
        width: '100%',
        transform: [{ scaleX: 2 }],
        borderBottomStartRadius: 200,
        borderBottomEndRadius: 200,
        overflow: 'hidden',
      }}>
        <View style={{
          width: '90%', marginHorizontal: '5%', flex: 1,
          transform: [{ scaleX: 0.5 }],
          alignItems: 'center',
          justifyContent: 'center'
        }}>
          <Text style={{ fontSize: 24, marginTop: 10, color: theme.colors.card }}>Verification Code</Text>
          <Text style={{color:theme.colors.card,fontSize:16,textAlign:'center',marginVertical:10}}>We have sent the verification code to Your Mobile Number</Text>
          <Text style={{color:theme.colors.card,fontSize:20}}>{phone}</Text>
        </View>
      </View>

      <View style={{ borderWidth: .1, elevation: 5, padding: 20, position: 'absolute', width: '90%', marginHorizontal: '5%', backgroundColor: theme.colors.card, marginTop: 300 }}>
      <View style={{justifyContent:'center',alignItems:'center'}}>
        {/**
         * <OTPInputView
          style={{ width: '80%', height: 100 }}
          pinCount={4}
          codeInputFieldStyle={styles.underlineStyleBase}
          codeInputHighlightStyle={styles.underlineStyleHighLighted}
          onCodeFilled={(code => { setOtp(code) })}
          selectionColor={"#ad1e3a"}
        />
         */}
        </View>
        <Button mode="contained" onPress={_verifyotp} loading={loading}>
          Verify & Create Account
        </Button>
      </View>
      <View style={{ marginTop: 150, justifyContent: 'center', width: '90%', alignItems: 'center', marginHorizontal: '5%' }}>
        <Text style={{ marginTop: 10 }}>Didnt received code?</Text>
        <Buton compact={true} contentStyle={{ height: 40 }} mode="text" onPress={resendO}>Request again</Buton>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingTop: 80,
    paddingBottom: 30,
    paddingRight: 20,
    paddingLeft: 20,
    width: '100%',
    maxWidth: 340,
    alignSelf: 'center',
    alignItems: 'center',
  },
  textInput: {
    height: 40,
    width: '95%',
    borderColor: 'red',
    borderWidth: 2,
    borderRadius: 20,
    marginBottom: 20,
    fontSize: 18,
  },
  forgotPassword: {
    width: '100%',
    alignItems: 'flex-end',
    marginBottom: 24,
  },
  row: {
    flexDirection: 'row',
    margin: 10,
  },
  label: {
    color: 'black',
  },
  link: {
    fontWeight: 'bold',
    color: '#ad1e3a',
  },
  borderStyleBase: {
    width: 30,
    height: 45
  },

  borderStyleHighLighted: {
    borderColor: "black",
  },

  underlineStyleBase: {
    width: 40,
    height: 40,
    borderWidth: 0,
    borderBottomWidth:1,
    color: 'black',
  },

  underlineStyleHighLighted: {
    borderColor: "#ad1e3a",
  },
});
