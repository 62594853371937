import React from 'react';
import {
  View,
  ScrollView,
  StyleSheet,
  RefreshControl
} from 'react-native';
import { useTheme, Text, IconButton } from 'react-native-paper';
import { useNavigation } from '@react-navigation/native';

import { Loader } from '../Components/Loader'
import { getReferralMember } from "../Services/api"
import Pagination from '../Components/Pagination'

export const ReferralList = () => {
  const theme = useTheme();
  const navigation = useNavigation();
  const [loading, setLoading] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);
  const [data, setData] = React.useState([]);
  const [pageNum, setPageNum] = React.useState(1);
  const [refreshing, setRefreshing] = React.useState(false);
  const [links, setLinks] = React.useState([]);

  async function fetchData(page) {
    const response = await getReferralMember(page);
    setData(response.data)
    setIsLoading(false);
    setLinks(response.pagination)
  }

  React.useEffect(() => {
    fetchData(pageNum);
  }, [])

  const onRefresh = React.useCallback(() => {
    setIsLoading(true);
    setRefreshing(true);
    fetchData(pageNum);
    setRefreshing(false);
  }, []);

  const pageChange = (page) => {
    setLoading(true)
    fetchData(page);
    setLoading(false)
    setPageNum(page)
  }

  if (isLoading) {
    return (<Loader />)
  } else {
    return (
      <View
        style={{
          flex: 1,
          backgroundColor: theme.colors.background,
        }}
      >
        <ScrollView contentContainerStyle={styles.scrolls}
          refreshControl={
            <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
          }>
          <View style={{ paddingTop: 25, height: 80, flexDirection: 'row' }}>
            <IconButton
              icon="arrow-left"
              color={theme.colors.primary}
              size={24}
              onPress={() => navigation.goBack()}
            />
            <Text style={{ marginVertical: 10, fontSize: 20 }}>Referral List</Text>
          </View>
          {data.length > 0 ?
            <View style={{ marginHorizontal: 10, alignItems: 'center' }}>
              {data.map((yitem, y) => (
                <View key={y} style={{ padding: 5, width: '100%', flex: 1, flexDirection: 'row', alignContent: 'center', alignItems: 'center', borderTopWidth: 1, borderColor: theme.colors.border }}>
                  <Text style={{ width: '75%' }}>{yitem.name}</Text>
                  <Text style={{ width: '25%' }}>{yitem.join}</Text>
                </View>
              ))}
            </View>
            :
            <View style={{ width: '100%', flex: 1, flexDirection: 'column', alignContent: 'center', alignItems: 'center', justifyContent: 'center' }}>
              <Text style={{ margin: 10 }}>No Referral Found</Text>
            </View>
          }
        </ScrollView>
        {links.total > links.pageSize ?
          <View style={{ borderWidth: 1, borderColor: theme.colors.border, height: 50, justifyContent: 'center', alignItems: 'center' }}>
            <Pagination onPageChange={pageChange} totalCount={links.total} currentPage={links.current_page} pageSize={links.pageSize} />
          </View>
          : null}
      </View>
    )
  }
}
const styles = StyleSheet.create({
  scrolls: {
    width: '100%',
    flexGrow: 1,
  },
})