import React from 'react';
import {
  View,
  ScrollView,
  StyleSheet,
} from 'react-native';
import { useTheme, Title, Button, Text } from 'react-native-paper';
import { useNavigation } from '@react-navigation/native';
import AwesomeAlert from 'react-native-awesome-alerts';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import { TicketInput, TicketInputRow } from '../Components'
import config from '../config'
import { useUserStore, useWalletStore } from '../Services/zustand'
import { addCart, walletBalance, getDiscount, gameTimer, gameGameStatus, gameLimits } from '../Services/api'
import Timer from '../Components/Timer'

export const Ticket = (props) => {
  const gameTitle = props.route.params.title
  const id = props.route.params.id
  const gametype = props.route.params.gametype
  const { gameid } = props.route.params

  const timerCallbackFunc = () => {
    setDisable(true)
  }
  const theme = useTheme();
  const navigation = useNavigation();

  const [total, setTotal] = React.useState(0);
  const [discount, setDiscount] = React.useState(5);
  const [disable, setDisable] = React.useState(false)
  const [limits, setLimits] = React.useState([])
  const { user } = useUserStore();
  const { giftWallet, setWallet } = useWalletStore();

  const [loading, setLoading] = React.useState(false);
  const [msg, setMsg] = React.useState('');
  const [list, setList] = React.useState([]);
  const [zoneId, setZoneId] = React.useState('');

  const [timer, setTimer] = React.useState('')
  const [showAlert, setShowAlert] = React.useState(false);
  const [mTitle, setMTitle] = React.useState('');
  const [mMessage, setMMessage] = React.useState('');

  React.useEffect(() => {
    fetchData()
  }, [])
  React.useEffect(() => {
    fetchTimer()
  }, [])

  async function fetchData() {
    const response = await getDiscount(id);
    setDiscount(response.data)
    if (id == '1') {
      const response1 = await gameLimits(id);
      setLimits(response1.data)
    } else if (id == '2') {
      const response1 = await gameLimits(id);
      setLimits(response1.data)
    }
  }
  async function fetchTimer() {
    const responseP = await gameGameStatus(id)
    if (responseP.data.status == 0) {
      setDisable(true)
    } else {
      const response = await gameTimer(gameid);
      setTimer(response.data[0].rotator)
    }
  }
  const PairNum = [{ "value": "All Pair", "tickets": "10", "zone_id": "48" }, { "value": "Half Pair", "tickets": "10", "zone_id": "47" }]
  const HouseNum = [{ "value": "0", "tickets": "10", "zone_id": "27" }, { "value": "1", "tickets": "10", "zone_id": "28" }, { "value": "2", "tickets": "10", "zone_id": "29" }, { "value": "3", "tickets": "10", "zone_id": "30" }, { "value": "4", "tickets": "10", "zone_id": "31" }, { "value": "5", "tickets": "10", "zone_id": "32" }, { "value": "6", "tickets": "10", "zone_id": "33" }, { "value": "7", "tickets": "10", "zone_id": "34" }, { "value": "8", "tickets": "10", "zone_id": "35" }, { "value": "9", "tickets": "10", "zone_id": "36" }]
  const EndingNum = [{ "value": "0", "tickets": "10", "zone_id": "37" }, { "value": "1", "tickets": "10", "zone_id": "38" }, { "value": "2", "tickets": "10", "zone_id": "39" }, { "value": "3", "tickets": "10", "zone_id": "40" }, { "value": "4", "tickets": "10", "zone_id": "41" }, { "value": "5", "tickets": "10", "zone_id": "42" }, { "value": "6", "tickets": "10", "zone_id": "43" }, { "value": "7", "tickets": "10", "zone_id": "44" }, { "value": "8", "tickets": "10", "zone_id": "45" }, { "value": "9", "tickets": "10", "zone_id": "46" }]
  const Bol = [
    { "value": "00", "tickets": "1" }, { "value": "01", "tickets": "1" }, { "value": "02", "tickets": "1" }, { "value": "03", "tickets": "1" }, { "value": "04", "tickets": "1" }, { "value": "05", "tickets": "1" }, { "value": "06", "tickets": "1" }, { "value": "07", "tickets": "1" }, { "value": "08", "tickets": "1" }, { "value": "09", "tickets": "1" },
    { "value": "10", "tickets": "1" }, { "value": "11", "tickets": "1" }, { "value": "12", "tickets": "1" }, { "value": "13", "tickets": "1" }, { "value": "14", "tickets": "1" }, { "value": "15", "tickets": "1" }, { "value": "16", "tickets": "1" }, { "value": "17", "tickets": "1" }, { "value": "18", "tickets": "1" }, { "value": "19", "tickets": "1" },
    { "value": "20", "tickets": "1" }, { "value": "21", "tickets": "1" }, { "value": "22", "tickets": "1" }, { "value": "23", "tickets": "1" }, { "value": "24", "tickets": "1" }, { "value": "25", "tickets": "1" }, { "value": "26", "tickets": "1" }, { "value": "27", "tickets": "1" }, { "value": "28", "tickets": "1" }, { "value": "29", "tickets": "1" },
    { "value": "30", "tickets": "1" }, { "value": "31", "tickets": "1" }, { "value": "32", "tickets": "1" }, { "value": "33", "tickets": "1" }, { "value": "34", "tickets": "1" }, { "value": "35", "tickets": "1" }, { "value": "36", "tickets": "1" }, { "value": "37", "tickets": "1" }, { "value": "38", "tickets": "1" }, { "value": "39", "tickets": "1" },
    { "value": "40", "tickets": "1" }, { "value": "41", "tickets": "1" }, { "value": "42", "tickets": "1" }, { "value": "43", "tickets": "1" }, { "value": "44", "tickets": "1" }, { "value": "45", "tickets": "1" }, { "value": "46", "tickets": "1" }, { "value": "47", "tickets": "1" }, { "value": "48", "tickets": "1" }, { "value": "49", "tickets": "1" },
    { "value": "50", "tickets": "1" }, { "value": "51", "tickets": "1" }, { "value": "52", "tickets": "1" }, { "value": "53", "tickets": "1" }, { "value": "54", "tickets": "1" }, { "value": "55", "tickets": "1" }, { "value": "56", "tickets": "1" }, { "value": "57", "tickets": "1" }, { "value": "58", "tickets": "1" }, { "value": "59", "tickets": "1" },
    { "value": "60", "tickets": "1" }, { "value": "61", "tickets": "1" }, { "value": "62", "tickets": "1" }, { "value": "63", "tickets": "1" }, { "value": "64", "tickets": "1" }, { "value": "65", "tickets": "1" }, { "value": "66", "tickets": "1" }, { "value": "67", "tickets": "1" }, { "value": "68", "tickets": "1" }, { "value": "69", "tickets": "1" },
    { "value": "70", "tickets": "1" }, { "value": "71", "tickets": "1" }, { "value": "72", "tickets": "1" }, { "value": "73", "tickets": "1" }, { "value": "74", "tickets": "1" }, { "value": "75", "tickets": "1" }, { "value": "76", "tickets": "1" }, { "value": "77", "tickets": "1" }, { "value": "78", "tickets": "1" }, { "value": "79", "tickets": "1" },
    { "value": "80", "tickets": "1" }, { "value": "81", "tickets": "1" }, { "value": "82", "tickets": "1" }, { "value": "83", "tickets": "1" }, { "value": "84", "tickets": "1" }, { "value": "85", "tickets": "1" }, { "value": "86", "tickets": "1" }, { "value": "87", "tickets": "1" }, { "value": "88", "tickets": "1" }, { "value": "89", "tickets": "1" },
    { "value": "90", "tickets": "1" }, { "value": "91", "tickets": "1" }, { "value": "92", "tickets": "1" }, { "value": "93", "tickets": "1" }, { "value": "94", "tickets": "1" }, { "value": "95", "tickets": "1" }, { "value": "96", "tickets": "1" }, { "value": "97", "tickets": "1" }, { "value": "98", "tickets": "1" }, { "value": "99", "tickets": "1" },
  ]
  const GroupgNum = [
    { "value": "00", "tickets": "4", "zone_id": "1" }, { "value": "14", "tickets": "8", "zone_id": "9" }, { "value": "01", "tickets": "8", "zone_id": "2" },
    { "value": "22", "tickets": "4", "zone_id": "10" }, { "value": "02", "tickets": "8", "zone_id": "3" }, { "value": "23", "tickets": "8", "zone_id": "11" },
    { "value": "03", "tickets": "8", "zone_id": "4" }, { "value": "24", "tickets": "8", "zone_id": "12" }, { "value": "04", "tickets": "8", "zone_id": "5" },
    { "value": "33", "tickets": "4", "zone_id": "13" }, { "value": "11", "tickets": "4", "zone_id": "6" }, { "value": "34", "tickets": "8", "zone_id": "14" },
    { "value": "12", "tickets": "8", "zone_id": "7" }, { "value": "44", "tickets": "4", "zone_id": "15" }, { "value": "13", "tickets": "8", "zone_id": "8" }
  ]
  const FutaNum = [
    { "value": "0", "tickets": "1", "zone_id": "17" }, { "value": "1", "tickets": "11", "zone_id": "18" }, { "value": "2", "tickets": "11", "zone_id": "19" },
    { "value": "3", "tickets": "11", "zone_id": "20" }, { "value": "4", "tickets": "11", "zone_id": "21" }, { "value": "5", "tickets": "11", "zone_id": "22" },
    { "value": "6", "tickets": "11", "zone_id": "23" }, { "value": "7", "tickets": "11", "zone_id": "24" }, { "value": "8", "tickets": "11", "zone_id": "25" },
    { "value": "9", "tickets": "11", "zone_id": "26" }
  ]

  const renderSwitch = () => {
    switch (gametype) {
      case 'bol':
        return (
          Bol.map((item, k) =>
            <TicketInput key={k} items={item} gameid={id} gametype={gametype} callback={processItem} disabled={disable} limit={limits} />
          )
        )
      case 'group':
        return (
          GroupgNum.map((item, k) =>
            <TicketInput key={k} items={item} gameid={id} gametype={gametype} callback={processItem} disabled={disable} limit={limits} />
          )
        )
      case 'house':
        return (
          HouseNum.map((item, k) =>
            <TicketInput key={k} items={item} gameid={id} gametype={gametype} callback={processItem} disabled={disable} limit={limits} />
          )
        )
      case 'ending':
        return (
          EndingNum.map((item, k) =>
            <TicketInput key={k} items={item} gameid={id} gametype={gametype} callback={processItem} disabled={disable} limit={limits} />
          )
        )
      case 'pair':
        return (
          PairNum.map((item, k) =>
            <TicketInputRow key={k} items={item} gameid={id} gametype={gametype} callback={processItem} disabled={disable} limit={limits} />
          )
        )
      case 'futa':
        return (
          FutaNum.map((item, k) =>
            <TicketInput key={k} items={item} gameid={id} gametype={gametype} callback={processItem} disabled={disable} limit={limits} />
          )
        )
      default:
        return null;
    }
  }

  const processPay = async () => {
    setLoading(true)
    if (total < 5) {
      setMTitle('Alert')
      setMMessage('Minimum Bid amount is Rs 5')
      setShowAlert(true)
    } else {
      if (list.length > 0) {
        let itemToAdd = { title: gameTitle, gametype: gametype, data: list, item_id: id, amount: total };
        const response = await addCart(itemToAdd);
        if (response.data != '') {
          setList([])
          setWallet({ wallet: response.data.wallet, discount: response.data.discount, winWallet: response.data.win, giftWallet: giftWallet })
          setTotal(0);
          navigation.navigate('Process', { message: "You had successfully Purchase Ticket" })
        } else {
          setMTitle('Alert')
          setMMessage('Fund not enough to Pay! Add Fund or Delete Ticket')
          setShowAlert(true)
        }

      } else {
        setMTitle('Alert')
        setMMessage('Bid Number not Found')
        setShowAlert(true)
      }
    }
  }
  const processItem = (value, amount, rate, zoneId, alert) => {
    if (alert) {
      setMTitle('Alert!')
      setMMessage('Limit exceed Rs 1000, please check')
      setShowAlert(true)
    } else {
      upsert(list, { item: value, price: amount, rate: rate, total: amount * rate, zone_id: zoneId, discount: amount * discount / 100 })
      calculateTotal()
    }
  }
  const upsert = (array, element) => {
    const i = array.findIndex(_element => _element.item === element.item);
    if (i > -1) array[i] = element;
    else array.push(element);
  }
  const calculateTotal = () => {
    let total = 0;
    for (var i = 0; i < list.length; i++) {
      total += list[i].total;
    }
    setTotal(total)
  }

  return (
    <View
      style={{
        flex: 1,
        backgroundColor: theme.colors.background,
      }}
    >
      <View style={{ width: '100%', paddingHorizontal: '5%', backgroundColor: theme.colors.background, height: 60 }}>
        <View style={{ flexDirection: 'row', justifyContent: 'space-between', flex: 1 }}>
          <View style={{}}>
            <Title>{gameTitle}</Title>
            <Text style={{ marginBottom: 10, textTransform: 'uppercase' }}>{gametype}</Text>
          </View>
          <View style={{}}>
            {timer != '' ?
              <Timer
                timestamp={timer}
                timerCallback={timerCallbackFunc}
              />
              : null
            }
          </View>
        </View>
      </View>
      <ScrollView contentContainerStyle={styles.scrolls}>
        <View style={{ width: '90%', marginLeft: '5%' }}>
          <View style={{ flexWrap: 'wrap', justifyContent: 'space-between', flexDirection: 'row', flex: 3 }}>
            {renderSwitch()}
          </View>
        </View>
        <View style={{ width: '100%', backgroundColor: theme.colors.background }}>
          <View style={{ marginHorizontal: '5%', borderRadius: 10, borderWidth: 1, padding: 10, marginVertical: 10, backgroundColor: theme.colors.card }}>
            <View style={{ flexDirection: 'row', justifyContent: 'space-between', height: 30, alignItems: 'center' }}>
              <Text>Total</Text>
              {total > 0 &&
                <Title style={{ fontSize: 16 }}>
                  <MaterialCommunityIcons name={'currency-inr'} size={16} color={theme.colors.text} /> {total}.00
                </Title>
              }
            </View>
            <View style={{ flexDirection: 'row', justifyContent: 'space-between', height: 30, alignItems: 'center' }}>
              <Text>Discount {discount}%</Text>
              {total > 0 &&
                <Title style={{ fontSize: 16 }}>
                  <MaterialCommunityIcons name={'currency-inr'} size={16} color={theme.colors.text} /> {(discount * total) / 100}
                </Title>
              }
            </View>
            <Text style={{ fontWeight: 'bold', color: theme.colors.info }}>Please Note:</Text>
            <Text>Discount will be transfered to Discount wallet</Text>
          </View>
          <Button
            labelStyle={{ lineHeight: 26, color: 'white', }}
            style={{ width: '100%' }}
            contentStyle={{ height: 60, backgroundColor: disable ? theme.colors.thirdText : total > 0 ? theme.colors.primary : theme.colors.thirdText, borderRadius: 0 }}
            disabled={disable ? disable : total > 0 ? false : true}
            loading={loading}
            onPress={() => processPay()}>
            {total > 0 ? "Pay Now " + "(" + config.currencySign + total + ".00)" : "Pay Now"}
          </Button>
        </View>
        <AwesomeAlert
          show={showAlert}
          showProgress={false}
          title={mTitle}
          message={mMessage}
          closeOnTouchOutside={true}
          closeOnHardwareBackPress={false}
          showCancelButton={false}
          showConfirmButton={true}
          confirmText="OK"
          cancelButtonColor={theme.colors.secondary}
          onConfirmPressed={() => {
            setShowAlert(false);
            setLoading(false)
          }}
          overlayStyle={{height: '100%'}}
        />
      </ScrollView>
    </View>
  );
}
const styles = StyleSheet.create({
  scrolls: {
    width: '100%',
    flexGrow: 1,
    paddingBottom: 10,
  },
  styleManufacture: {
    width: '31%',
    height: 120,
    marginVertical: '1%',
    marginHorizontal: '1%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  styleBranch: {
    width: '48%',
    marginVertical: '1%',
    marginHorizontal: '1%',
    paddingHorizontal: 5,
    paddingVertical: 10,
  },
  thumbnail: {
    width: 50,
    height: 50,
    margin: 10,
  },
})
