import AsyncStorage from '@react-native-async-storage/async-storage';

export async function LoggedInUser(token) {
  await AsyncStorage.setItem('token', token);
  return true
}

export async function logout() {
  try {
    await AsyncStorage.removeItem('token');
    return true
  } catch (error) {
    return false
  }
}