import React from 'react';
import {
  View,
} from 'react-native';
import { useTheme, Text } from 'react-native-paper';

export const GameStatus = (props) => {
  const theme = useTheme();
  const winamount=props.winamount
  const id=props.id

  return (
    <View style={{ alignContent: 'center', alignItems: 'center', borderWidth: 1, borderColor: winamount > 0 ? theme.colors.success : theme.colors.border,backgroundColor:winamount > 0 ? theme.colors.success : theme.colors.card }}>
      <Text style={{ color: winamount > 0 ? theme.colors.card : theme.colors.text }}>{winamount > 0 ? winamount : 'No win'}</Text>
    </View>
  )
}