import React from 'react';
import {
  View,
  Text,
} from 'react-native';
import { IconButton, useTheme, TouchableRipple } from 'react-native-paper';
import { usePagination, DOTS } from '../Hook/usePagination';

const Pagination = props => {
  const {
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize,
  } = props;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize
  });

  //if (currentPage === 0 || paginationRange.length < 2) {
    //return null;
  //}

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  //let lastPage = paginationRange[paginationRange.length - 1];
  const theme = useTheme();
  return (
    <View style={{flexDirection:'row'}}>
      <View>
        <IconButton
          icon={"arrow-left-circle-outline"}
          color={theme.colors.primary}
          size={20}
          onPress={onPrevious}
        />
      </View>
      {paginationRange.map((pageNumber, k) => (
        pageNumber === DOTS
          ? (<View key={k} style={{height:30,width:30,margin:5,justifyContent:'center',alignItems:'center'}}><Text>...</Text></View>)
          : (
            <TouchableRipple
            onPress={() => onPageChange(pageNumber)}
            rippleColor="rgba(0, 0, 0, .32)"
            key={k}
            borderless={true}
            style={{height:30,width:30,borderColor:theme.colors.primary,borderWidth:pageNumber === currentPage?0:1,borderRadius:15,margin:5,justifyContent:'center',alignItems:'center'}}
          >            
            <Text style={{color:theme.colors.primary}}>{pageNumber}</Text>
          </TouchableRipple>
          )
      ))}
      <View>
        <IconButton
          icon={"arrow-right-circle-outline"}
          color={theme.colors.primary}
          size={20}
          onPress={onNext}
        />
      </View>
    </View>
  );
};

export default Pagination;