import React from 'react';
import {
  View,
  ScrollView,
  StyleSheet,
} from 'react-native';
import { useTheme, TextInput, Text, Title, Caption, Headline, Button, IconButton } from 'react-native-paper';

import { Loader } from '../Components/Loader'
import { gameRates,gameCommission } from "../Services/api"

export const Rates = () => {
  const theme = useTheme();
  const [isLoading, setIsLoading] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [commission, setCommission] = React.useState([]);

  React.useEffect(() => {
    async function fetchData() {
      const response = await gameRates();
      setData(response.data)
      const res = await gameCommission();
      setCommission(res.data)
      setIsLoading(false);
    }
    fetchData();
  }, [])
  if (isLoading) {
    return (<Loader />)
  } else {
    return (
      <View
        style={{
          flex: 1,
          backgroundColor: theme.colors.surface,
        }}
      >
        <ScrollView contentContainerStyle={styles.scrolls}>
          <>
            <View style={{ width: '100%', padding: 10,marginLeft:10 }}>
              <Title>Game Rate List</Title>
            </View>
            <View style={{ width: '90%', flexDirection: 'row', borderWidth:1,marginHorizontal:'5%' }}>
              <Text style={{ padding: 10,width:250 }}>Game</Text>
              <View style={{ borderWidth: 1, width: 1, }}></View>
              <Text style={{ padding: 10 }}>Rates</Text>
            </View>
            {
              data.map((item, k) => (
                <View key={k} style={{ width: '90%', flexDirection: 'row',borderBottomWidth:1,borderRightWidth:1,borderLeftWidth:1,marginHorizontal:'5%' }}>
                  <Text style={{ padding: 10,width:250 }}>{item.game.title}</Text>
                  <View style={{ borderWidth: 1, width: 1, }}></View>
                  <Text style={{ padding: 10 }}>{item.number}</Text>
                </View>
              ))
            }
            <View style={{ width: '100%', padding: 10,marginLeft:10 }}>
              <Title>Discount List</Title>
            </View>
            <View style={{ width: '90%', flexDirection: 'row', borderWidth:1,marginHorizontal:'5%' }}>
              <Text style={{ padding: 10,width:250 }}>Game</Text>
              <View style={{ borderWidth: 1, width: 1, }}></View>
              <Text style={{ padding: 10 }}>Rates</Text>
            </View>
            {
              commission.map((item, k) => (
                <View key={k} style={{ width: '90%', flexDirection: 'row',borderBottomWidth:1,borderRightWidth:1,borderLeftWidth:1,marginHorizontal:'5%' }}>
                  <Text style={{ padding: 10,width:250 }}>{item.game.title}</Text>
                  <View style={{ borderWidth: 1, width: 1, }}></View>
                  <Text style={{ padding: 10 }}>{item.amount}</Text>
                </View>
              ))
            }
          </>
        </ScrollView>
      </View>
    )
  }
}
const styles = StyleSheet.create({
  scrolls: {
    width: '100%',
    flexGrow: 1,
  },
})