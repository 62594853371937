import React, {
  useState,
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { Text, View, StyleSheet } from 'react-native';
import { useTheme } from 'react-native-paper';

const Timer = forwardRef((props, ref) => {
  const theme = useTheme();
  const [timeStamp, setTimeStamp] = useState(
    props.timestamp ? props.timestamp : 0,
  );

  const [delay, setDelay] = useState(props.delay ? props.delay : 1000);

  const [sendOnce, setSendOnce] = useState(true);

  const [finalDisplayTime, setFinalDisplayTime] = useState('');

  useInterval(() => {
    if (timeStamp > 0) {
      setTimeStamp(timeStamp - 1);
    } else if (sendOnce) {
      if (props.timerCallback) {
        props.timerCallback(true);
      } else {
        console.log('Please pass a callback function...');
      }
      setSendOnce(false);
    }
    setFinalDisplayTime(getTimeLeft(timeStamp));
  }, delay);

  function getTimeLeft(until) {
    until = Number(until);
    var h = parseInt(until / (60 * 60), 10) % 24;
    var m = parseInt(until / 60, 10) % 60;
    var s = until % 60;

    var hDisplay = h > 0 ? h + ':' : '0:';
    var mDisplay = m > 0 ? m + ':' : '00:';
    var sDisplay = s > 0 ? s + ':' : '00:';
    return hDisplay + mDisplay + sDisplay;
  };

  const refTimer = useRef();
  useImperativeHandle(ref, () => ({
    resetTimer: () => {
      setTimeStamp(props.timestamp);
      setSendOnce(true);
    },
  }));

  function renderDoubleDigits (label, digits) {
    const size=15
    return (
      <View style={styles.doubleDigitCont}>
        <View style={styles.timeInnerCont}>
          <View style={[
            styles.digitCont,
            { width: size * 2.3, height: size * 2 },
          ]}>
            <Text style={[
              styles.digitTxt,
              { fontSize: size },
            ]}>
              {digits}
            </Text>
          </View>
        </View>
        <Text style={[
          styles.timeTxt,
          { fontSize: size / 1.8 },
        ]}>
          {label}
        </Text>
      </View>
    );
  };
  function renderTime(){
    const newTime=finalDisplayTime.split(':')
    const timeLabels = {
      h: 'Hours',
      m: 'Minutes',
      s: 'Seconds',
    };
    return(
      <View
        style={styles.timeCont}
        >
        {renderDoubleDigits(timeLabels.h, newTime[0])}
        {renderDoubleDigits(timeLabels.m, newTime[1])}
        {renderDoubleDigits(timeLabels.s, newTime[2])}
      </View>
    )
  }
  function renderText(){
    return(
      <View style={{backgroundColor:theme.colors.thirdText,height: 35, width: 110, borderRadius: 5,justifyContent:'center',alignItems:'center'}}>
        <Text style={{color:theme.colors.card,fontWeight:'bold'}}>Close</Text>
      </View>
    )
  }
  return (
    <View ref={refTimer} style={props.containerStyle}>
      {sendOnce ? renderTime() : renderText()}
    </View>
  );
});

function useInterval(callback, delay) {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => {
        clearInterval(id);
      };
    }
  }, [delay]);
}

export default Timer;
const styles = StyleSheet.create({
  timeCont: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
  timeTxt: {
    color: '#333',
    marginVertical: 2,
    backgroundColor: 'transparent',
  },
  timeInnerCont: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  digitCont: {
    borderRadius: 5,
    marginHorizontal: 2,
    alignItems: 'center',
    justifyContent: 'center',    
    backgroundColor:'#ad1e3a'
  },
  doubleDigitCont: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  digitTxt: {
    color: 'white',
    fontWeight: 'bold',
  },
  separatorTxt: {
    backgroundColor: 'transparent',
    fontWeight: 'bold',
  },
})