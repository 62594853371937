import React from 'react'
import { Image, View } from 'react-native'
import { Text, TouchableRipple, useTheme,Button } from 'react-native-paper';
import { useNavigation } from '@react-navigation/native';

import { Images } from '../Constants';
var noData = Images.noData;

const noDataIMG = <Image source={noData} style={{ opacity: 0.8, width: 150, height: (150 / (820 / 781)) }}></Image>;

export const Empty = (props) => {
  const theme = useTheme();
  const navigation = useNavigation();
  return (
    <View style={{ marginTop: 80,flex:1,justifyContent:'center',alignItems:'center' }}>
      {noDataIMG}
      <Text style={{ marginTop: 20 }}>You cart is Empty</Text>
      <Button
          mode="contained"
          labelStyle={{ color: 'white', }}
          uppercase={false}
          onPress={() => navigation.goBack()}
          style={{ backgroundColor: theme.colors.accent, width: '80%', borderRadius: 0,marginTop:20 }}
        >
          Go Back
          </Button>
    </View>
  )
}
