import React from 'react';
import {
  View,
  ScrollView,
  StyleSheet,
} from 'react-native';
import { useTheme, TextInput, Text, ProgressBar, Button, IconButton } from 'react-native-paper';
import AwesomeAlert from 'react-native-awesome-alerts';
import { getIfsc, addBank, updateBank } from "../Services/api"
import { useNavigation } from '@react-navigation/native';

export const BankDetails = ({ route }) => {
  const theme = useTheme();
  const navigation = useNavigation();

  const { mode } = route.params
  const rdata = route.params.data
  const { id } = route.params;

  const [isLoading, setIsLoading] = React.useState(false);
  const [isBank, setIsBank] = React.useState(false);

  const [account, setAccount] = React.useState({ value: '', error: '' });
  const [raccount, setRaccount] = React.useState({ value: '', error: '' });
  const [ifsc, setIfsc] = React.useState({ value: '', error: '' });
  const [username, setUsername] = React.useState({ value: '', error: '' });
  const [bname, setBname] = React.useState({ value: '', error: '' });

  const [secureEntry, setSecureEntry] = React.useState(false);

  const [showAlert, setShowAlert] = React.useState(false);
  const [mTitle, setMTitle] = React.useState('');
  const [mMessage, setMMessage] = React.useState('');

  React.useEffect(() => {
    if (mode == 1) {
      setAccount({ value: rdata.account_no, error: '' })
      setRaccount({ value: rdata.account_no, error: '' })
      setIfsc({ value: rdata.ifsc_no, error: '' })
      setUsername({ value: rdata.u_name, error: '' })
      setBname({ value: rdata.bank_name, error: '' })
    }
  }, [mode])
  const processBank = async () => {
    const accountError = fieldValidator(account.value);
    const raccountError = confirmValidator(account.value, raccount.value);
    const ifscError = fieldValidator(ifsc.value);
    const usernameError = fieldValidator(username.value);
    const bnameError = fieldValidator(bname.value);

    if (accountError || raccountError || ifscError || usernameError || bnameError) {
      setAccount({ ...account, error: accountError });
      setRaccount({ ...raccount, error: raccountError });
      setIfsc({ ...ifsc, error: ifscError });
      setUsername({ ...username, error: usernameError });
      setBname({ ...bname, error: bnameError });
      setSecureEntry(true)
      return;
    } else {
      setIsLoading(true)
      if (mode == 1) {
        const response = await updateBank(account.value, ifsc.value, username.value, bname.value, id);
        setIsLoading(false)
        setMTitle('Success')
        setMMessage(response.data)
        setShowAlert(true)
      } else {
        const response = await addBank(account.value, ifsc.value, username.value, bname.value);
        setIsLoading(false)
        setMTitle('Success')
        setMMessage(response.data)
        setShowAlert(true)
      }
    }
  }
  async function fetchBank() {
    const response = await getIfsc(ifsc.value);
    setBname({ value: response.data, error: '' })
    setIsBank(false)
  }
  const bankSearch = () => {
    if ((ifsc.value).length == 11) {
      setIsBank(true)
      fetchBank()
    }
  }
  const fieldValidator = (ref) => {
    if (!ref || ref.length <= 0) return 'This field cannot be empty.';

    return '';
  };
  const confirmValidator = (ref, val) => {
    if (!ref == val) return 'Confirm mismatch.';

    return '';
  };
  return (
    <View
      style={{
        flex: 1,
        backgroundColor: theme.colors.background,
        marginTop: 20
      }}
    >
      <View style={{ paddingTop: 25, height: 80, flexDirection: 'row' }}>
        <IconButton
          icon="arrow-left"
          color={theme.colors.primary}
          size={24}
          onPress={() => navigation.goBack()}
        />
        <Text style={{ marginVertical: 10, fontSize: 20 }}>Bank Details</Text>
      </View>
      <ScrollView contentContainerStyle={styles.scrolls}>
        <View style={{ width: '100%', padding: 10 }}>
          <TextInput
            label="Account Number"
            value={account.value}
            onChangeText={text => setAccount({ value: text, error: '' })}
            mode={'outlined'}
            style={{ width: '100%', marginTop: 10 }}
            outlineColor={theme.colors.border}
            activeOutlineColor={theme.colors.primary}
            secureTextEntry={secureEntry}
          />
          {account.error ? <Text style={styles.error}>{account.error}</Text> : null}
          <TextInput
            label="Confirmr Account Number"
            value={raccount.value}
            onChangeText={text => {
              setRaccount({ value: text, error: '' })
              setSecureEntry(true)
            }
            }
            mode={'outlined'}
            style={{ width: '100%', marginTop: 10 }}
            outlineColor={theme.colors.border}
            activeOutlineColor={theme.colors.primary}
          />
          {raccount.error ? <Text style={styles.error}>{raccount.error}</Text> : null}
          <TextInput
            autoCapitalize={"characters"}
            label="IFSC"
            value={ifsc.value}
            onChangeText={text => setIfsc({ value: text, error: '' })}
            mode={'outlined'}
            style={{ width: '100%', marginTop: 10 }}
            outlineColor={theme.colors.border}
            activeOutlineColor={theme.colors.primary}
          />
          {ifsc.error ? <Text style={styles.error}>{ifsc.error}</Text> : null}
          <TextInput
            label="Bank Name"
            value={bname.value}
            onChangeText={text => setBname({ value: text, error: '' })}
            mode={'outlined'}
            style={{ width: '100%', marginTop: 10 }}
            outlineColor={theme.colors.border}
            activeOutlineColor={theme.colors.primary}
            editable={false}
            right={<TextInput.Icon name="text-search" onPress={() => bankSearch()} />}
          />
          <ProgressBar progress={0} color={theme.colors.primary} di visible={isBank}/>
          {bname.error ? <Text style={styles.error}>{username.error}</Text> : null}
          <TextInput
            label="Recipient name"
            value={username.value}
            onChangeText={text => setUsername({ value: text, error: '' })}
            mode={'outlined'}
            style={{ width: '100%', marginTop: 10 }}
            outlineColor={theme.colors.border}
            activeOutlineColor={theme.colors.primary}
          />
          {username.error ? <Text style={styles.error}>{username.error}</Text> : null}
          <Button
            style={{ backgroundColor: theme.colors.primary, marginTop: 10 }}
            labelStyle={{ color: '#fff' }}
            mode="contained"
            onPress={() => processBank()}
            contentStyle={{ width: '100%', height: 50 }}
            loading={isLoading}
          >
            {mode == 1 ? 'Update Bank' : 'Add Bank'}
          </Button>
        </View>
        <AwesomeAlert
          show={showAlert}
          showProgress={false}
          title={mTitle}
          message={mMessage}
          closeOnTouchOutside={true}
          closeOnHardwareBackPress={false}
          showCancelButton={false}
          showConfirmButton={true}
          confirmText="OK"
          cancelButtonColor={theme.colors.secondary}
          onCancelPressed={() => {
            setShowAlert(false);
          }}
          onConfirmPressed={() => {
            setShowAlert(false);
            navigation.goBack()
          }}
        />
      </ScrollView>
    </View>
  )
}
const styles = StyleSheet.create({
  scrolls: {
    width: '100%',
    flexGrow: 1,
  },
  error: {
    fontSize: 14,
    color: 'red',
    paddingHorizontal: 4,
    paddingTop: 4,
  },
})