import React from 'react';
import {
  View,
} from 'react-native';

import { useNavigation } from '@react-navigation/native';
import { useTheme, Text, Button } from 'react-native-paper';
import { gameTimer, gameGameStatus } from '../Services/api'
import Timer from '../Components/Timer'

export const TicketType = ({ route }) => {
  const theme = useTheme();
  const navigation = useNavigation();
  const [disable, setDisable] = React.useState(false)
  const { title } = route.params
  const { id } = route.params
  const { gameid } = route.params
  const { house } = route.params
  const [timer, setTimer] = React.useState('')

  const timerCallbackFunc = () => {
    setDisable(true)
  }
  React.useEffect(() => {
    fetchData();
  }, [])
  async function fetchData() {
    const responseP = await gameGameStatus(id)

    if (responseP.data.status == 0) {
      setDisable(true)
    } else {
      const response = await gameTimer(gameid);
      setTimer(response.data[0].rotator)
    }
  }
  return (
    <View style={{ width: '80%', marginLeft: '10%', justifyContent: 'center', alignItems: 'center', marginTop: 100 }}>
      <Text style={{ fontSize: 24, marginBottom: 10 }}>{title}</Text>
      {timer != '' ?
        <Timer
          timestamp={timer}
          timerCallback={timerCallbackFunc}
        />
        : null
      }
      <View style={{ width: '100%', flexDirection: 'row', justifyContent: 'space-between', marginTop: 20 }}>
        <Button
          style={{ backgroundColor: disable ? theme.colors.thirdText : theme.colors.primary, width: 130, marginBottom: 20 }}
          disabled={disable}
          labelStyle={{ color: '#fff' }}
          mode="contained"
          onPress={() => navigation.navigate('Ticket', { title: title, id: id, gametype: 'bol', gameid: gameid })}
          contentStyle={{ height: 80 }}
        >
          Bol/Gutti
        </Button>
        <Button
          style={{ backgroundColor: disable ? theme.colors.thirdText : theme.colors.secondary, width: 130, marginBottom: 20 }}
          disabled={disable}
          labelStyle={{ color: '#fff' }}
          mode="contained"
          onPress={() => navigation.push('Ticket', { title: title, id: id, gametype: 'group', gameid: gameid })}
          contentStyle={{ height: 80 }}
        >
          Group
        </Button>
      </View>
      <View style={{ width: '100%', flexDirection: 'row', justifyContent: 'space-between' }}>
        <Button
          style={{ backgroundColor: house?theme.colors.thirdText:disable ? theme.colors.thirdText : theme.colors.primary, width: 130, marginBottom: 20 }}
          disabled={house?true:disable}
          labelStyle={{ color: '#fff' }}
          mode="contained"
          onPress={() => navigation.push('Ticket', { title: title, id: id, gametype: 'house', gameid: gameid })}
          contentStyle={{ height: 80 }}
        >
          House
        </Button>
        <Button
          style={{ backgroundColor: disable ? theme.colors.thirdText : theme.colors.secondary, width: 130, marginBottom: 20 }}
          disabled={disable}
          labelStyle={{ color: '#fff' }}
          mode="contained"
          onPress={() => navigation.push('Ticket', { title: title, id: id, gametype: 'ending', gameid: gameid })}
          contentStyle={{ height: 80 }}
        >
          Ending
        </Button>
      </View>
      <View style={{ width: '100%', flexDirection: 'row', justifyContent: 'space-between' }}>
        <Button
          style={{ backgroundColor: disable ? theme.colors.thirdText : theme.colors.primary, width: 130, marginBottom: 20 }}
          disabled={disable}
          labelStyle={{ color: '#fff' }}
          mode="contained"
          onPress={() => navigation.push('Ticket', { title: title, id: id, gametype: 'pair', gameid: gameid })}
          contentStyle={{ height: 80 }}
        >
          Pair
        </Button>
        <Button
          style={{ backgroundColor: disable ? theme.colors.thirdText : theme.colors.secondary, width: 130, marginBottom: 20 }}
          disabled={disable}
          labelStyle={{ color: '#fff' }}
          mode="contained"
          onPress={() => navigation.push('Ticket', { title: title, id: id, gametype: 'futa', gameid: gameid })}
          contentStyle={{ height: 80 }}
        >
          Futa
        </Button>
      </View>
      {id == '1' ?
        <View style={{ borderWidth: 1, borderRadius: 5, padding: 20 }}>
          <Text>Please Note:</Text>
          <Text>Bol/Gutti maximum Hit Rs 1000.00 per ID</Text>
        </View>
        : id == '2' ?
          <View style={{ borderWidth: 1, borderRadius: 5, padding: 20 }}>
            <Text>Please Note:</Text>
            <Text>Bol/Gutti maximum Hit Rs 1000.00 per ID</Text>
          </View>
          : null
      }
    </View>
  )
}