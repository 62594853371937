import React, { useEffect, useState } from 'react';
import {
  Keyboard,
  Text,
  View,
  Alert,
  StyleSheet,
  KeyboardAvoidingView,
  ScrollView,
  Linking,
} from 'react-native';
import {
  Snackbar,
  useTheme,
  Button as Buton
} from 'react-native-paper';
import BouncyCheckbox from "react-native-bouncy-checkbox";
import Button from './components/Button';
import TextInput from './components/TextInput';
import { phoneValidator, passwordValidator, confirmValidator } from './core/utils';
import { registerPhone } from "./../../Services/api"

export const PhoneRegister = ({ navigation }) => {
  const [isSelected, setSelection] = useState(false);
  const [mobile, setMobile] = useState({ value: '', error: '' });
  const [pass, setPass] = useState({ value: '', error: '' });
  const [cpass, setCpass] = useState({ value: '', error: '' });
  const [referral, setReferral] = useState({ value: 'AA100000', error: '' });
  const [loading, setLoading] = useState(false);
  const theme = useTheme();

  const _onSendOTPPressed = async () => {
    setLoading(true);
    const mobileError = phoneValidator(mobile.value);
    const passdordError = passwordValidator(pass.value);
    const confirmError = confirmValidator(cpass.value);
    if (mobileError) {
      setMobile({ ...mobile, error: mobileError });
      return;
    } else if(passdordError){
      setPass({ ...pass, error: passdordError });
      return;
    } else if(confirmError){
        setPass({ ...cpass, error: confirmError });
        return;
    } else {
      let responseJson = await registerPhone(mobile.value,pass.value, referral.value)
      console.log(responseJson.error)
      if (responseJson.error) {
        Alert.alert('Error Mobile No', "This mobile is already registered", [
          { text: 'OK', onPress: () => console.log('OK Pressed') },
        ]);
        setLoading(false);
      }
      if (responseJson.data) {
        setLoading(false);
        navigation.navigate('PhoneLogin')
      }
    }
  }
  return (
    <View
      style={{ width: '100%', flex: 1 }}
    >
      <View style={{
        backgroundColor: theme.colors.primary,
        height: 200,
        width: '100%',
        transform: [{ scaleX: 2 }],
        borderBottomStartRadius: 200,
        borderBottomEndRadius: 200,
        overflow: 'hidden',
      }}>
        <View style={{
          width: '90%', marginHorizontal: '5%', flex: 1,
          transform: [{ scaleX: 0.5 }],
          alignItems: 'center',
          justifyContent: 'center'
        }}>
          <Text style={{ fontSize: 24, marginTop: 10, color: theme.colors.card }}>Register Account</Text>
        </View>
      </View>

      <View style={{ borderWidth: .1, elevation: 5, padding: 20, position: 'absolute', width: '90%', marginHorizontal: '5%', backgroundColor: theme.colors.card, marginTop: 140 }}>
      <TextInput
          placeholder="Mobile No"
          value={mobile.value}
          onChangeText={(text) => setMobile({ value: text, error: '' })}
          error={!!mobile.error}
          errorText={mobile.error}
          autoCapitalize="none"
          autoCompleteType="off"
          textContentType="telephoneNumber"
          keyboardType="number-pad"
          underlineColorAndroid="transparent"
          icon="phone-outline"
        />
        <TextInput
          placeholder="Password"
          value={pass.value}
          onChangeText={(text) => setPass({ value: text, error: '' })}
          error={!!pass.error}
          errorText={pass.error}
          autoCapitalize="none"
          autoCompleteType="off"
          textContentType="telephoneNumber"
          keyboardType="number-pad"
          underlineColorAndroid="transparent"
          icon="form-textbox-password"
          secureTextEntry={true}
        />
        <TextInput
          placeholder="Confirm Password"
          value={cpass.value}
          onChangeText={(text) => setCpass({ value: text, error: '' })}
          error={!!cpass.error}
          errorText={cpass.error}
          autoCapitalize="none"
          autoCompleteType="off"
          textContentType="telephoneNumber"
          keyboardType="number-pad"
          underlineColorAndroid="transparent"
          icon="form-textbox-password"
        />
        <TextInput
          placeholder="Referral Code"
          value={referral.value}
          onChangeText={(text) => setReferral({ value: text, error: '' })}
          autoCapitalize="none"
          autoCompleteType="off"
          underlineColorAndroid="transparent"
          icon="account-network-outline"
        />
        <View style={styles.checkboxContainer}>
          <BouncyCheckbox
            fillColor={theme.colors.primary}
            unfillColor="#FFFFFF"
            onPress={() => { setSelection(!isSelected) }}
            style={{width:'12%'}}
          />
          <Text style={styles.label}>I accept the terms of services and privacy policy</Text>
        </View>
        <View style={styles.checkboxContainer}>
          <BouncyCheckbox
            fillColor={theme.colors.primary}
            unfillColor="#FFFFFF"
            onPress={() => { setSelection(!isSelected) }}
            style={{width:'12%'}}
          />
          <Text style={styles.label}>I am above 18 and not using the services of online Teer play from Assam, Nagaland, Odisha, Sikkim, and Telangana.</Text>
        </View>
        <Button mode="contained" onPress={_onSendOTPPressed} loading={loading} style={{ marginBottom: 25 }}>
          Register Now
        </Button>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  containerView: {
    flex: 1,
    backgroundColor: '#f7f7f7',
  },
  container: {
    flex: 1,
    paddingTop: 30,
    paddingBottom: 30,
    paddingRight: 20,
    paddingLeft: 20,
    width: '100%',
    maxWidth: 340,
    alignSelf: 'center',
    alignItems: 'center',
  },
  textInput: {
    height: 40,
    width: '95%',
    borderColor: 'red',
    borderWidth: 2,
    borderRadius: 20,
    marginBottom: 20,
    fontSize: 18,
  },
  forgotPassword: {
    width: '100%',
    alignItems: 'flex-end',
    marginBottom: 24,
  },
  row: {
    flexDirection: 'row',
    margin: 10,
  },
  label: {
    color: 'black',
  },
  link: {
    fontWeight: 'bold',
    color: '#cd7f15',
  },
  borderStyleBase: {
    width: 30,
    height: 45
  },

  borderStyleHighLighted: {
    borderColor: "black",
  },

  underlineStyleBase: {
    width: 30,
    height: 45,
    borderWidth: 0,
    borderBottomWidth: 1,
    color: 'black'
  },
  underlineStyleHighLighted: {
    borderColor: "#03DAC6",
  },
  checkboxContainer: {
    flexDirection: 'row',
    marginBottom: 20,
    width:'100%'
  },
  checkbox: {
    alignSelf: 'center',
  },
  label: {
    width:'90%'
  },
});
