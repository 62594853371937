//App URL
exports.domain = "https://napp.sgteer.com";

//Currency
exports.currency="INR";
exports.currencySign="₹";

//Version
exports.version="1.2.6";

//APP API secert
exports.APP_SECRET="MxwOn6gNJs="; //Your app secret - same as in the .env file in your web project 
